import React from 'react';
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import LogoutButton from '../logoutButton/LogoutButton';
import Grid from '@material-ui/core/Grid';

const Copyright = ({hideLogout = false}) => {
    return (
        <>
            <Typography variant="body2" color="textSecondary" align="center">
                {'Copyright © '}
                <Link color="inherit" href="https://tlcworksinventory.com/">
                    TLC Works
                </Link>
                {' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
            {!hideLogout && (
                <Grid container justify="center" style={{ marginTop: '16px' }}>
                    <LogoutButton />
                </Grid>
            )}
        </>
    );
};

export default Copyright;

import {
    EDITING_REPAIR_SAVE, REPAIR_DELETE,
} from '../actions/action-types';
import { pendingActionType, successActionType, failureActionType } from './utils';

const initialState = {
    isSavingRepair: false,
    savingRepairError: null,
    freshRepairs: null,
    isDeletingRepair: false,
    deletingRepairError: false,
};

const repairs = (state = initialState, action) => {
    switch (action.type) {
        case pendingActionType(EDITING_REPAIR_SAVE):
            return {
                ...state,
                ...{
                    isSavingRepair: true,
                }
            };
        case failureActionType(EDITING_REPAIR_SAVE):
            return {
                ...state,
                ...{
                    isFetching: false,
                    savingRepairError: action.payload,
                }
            };
        case pendingActionType(REPAIR_DELETE):
            return {
                ...state,
                ...{
                    isDeletingRepair: true,
                }
            };
        case successActionType(REPAIR_DELETE):
            return {
                ...state,
                ...{
                    isDeletingRepair: false,
                }
            };
        case failureActionType(REPAIR_DELETE):
            return {
                ...state,
                ...{
                    isDeletingRepair: false,
                    deletingRepairError: action.payload,
                }
            };
        default:
            return state;
    }
};

export default repairs;

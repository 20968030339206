import React from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

const Alert = (props) => {
    const { alertTitle } = props;
    const newProps = { ...props };
    if (newProps.alertTitle) {
        delete newProps.alertTitle;
    }
    return <MuiAlert elevation={6} variant="filled" {...newProps}>
        {alertTitle && (
            <AlertTitle>{alertTitle}</AlertTitle>
        )}
        {props.children}
    </MuiAlert>;
}

export default Alert;

import React, { useCallback, useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ButtonSuccess from '../../../components/buttonSuccess/ButtonSuccess';
import ButtonError from '../../../components/buttonError/ButtonError';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import LoadingIcon from '../../../components/loadingIcon/LoadingIcon';
import usePrevious from '../../../hooks/usePrevious';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '../../../components/alert/Alert';
import { resetSaveUserError, saveUser } from '../../../redux/actions/users';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import useTheme from '@material-ui/core/styles/useTheme';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
    smallText: {
        fontSize: '90%',
        textAlign: 'center',
    },
    iconButtonDanger: {
        color: theme.palette.error.main,
        '&:hover': {
            color: theme.palette.error.dark,
        },
        '&:active': {
            color: theme.palette.error.dark,
        },
        '&:focus': {
            color: theme.palette.error.dark,
        },
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    formControl: {
        margin: theme.spacing(1, 0),
        minWidth: '100%',
    },
    multipleSelect: {
        root: {
            height: 'auto',
        }
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const getStyles = (userRole, userRoles = [], theme) => {
    return {
        fontWeight:
            userRoles.find(x => x.name === userRole.name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const roles = [
    {
        name: 'editor',
        alias: 'editor',
    },
    {
        name: 'viewer',
        alias: 'viewer',
    }
];

export default function EditUserModal({ user, _isOpen = false, closeFunc }) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();

    const isSavingUser = useSelector(store => store.users.isSavingUser, shallowEqual);
    const savingUserError = useSelector(store => store.users.savingUserError, shallowEqual);
    const prevIsSavingUser = usePrevious(isSavingUser);

    const [open, setOpen] = useState(false);
    const [name, setName] = useState((user && user.name) || '');
    const [email, setEmail] = useState((user && user.email) || '');
    const [password, setPassword] = useState('');
    const [isToastOpen, setToastIsOpen] = useState(false);
    const [userRoles, setUserRoles] = useState((user && user.roles) || []);
    const [isNew] = useState(!!(user && user._id));

    const handleChange = (event) => {
        setUserRoles(event.target.value);
    };

    useEffect(() => {
        setOpen(_isOpen);
    }, [_isOpen]);

    const handleCloseToast = useCallback(() => {
        setToastIsOpen(false);
        dispatch(resetSaveUserError());
    }, [dispatch]);

    useEffect(() => {
        if (prevIsSavingUser === true && isSavingUser === false) {
            // do we need to show error?
            if (savingUserError) {
                setToastIsOpen(true);
            } else {
                if (closeFunc) {
                    closeFunc();
                }
                // setOpen(false);
            }
        }
    }, [isSavingUser, _isOpen, prevIsSavingUser, handleCloseToast, isToastOpen, savingUserError, closeFunc]);

    const handleClose = () => {
        setOpen(false);
        if (closeFunc) {
            closeFunc();
        }
    };

    const onSaveUser = useCallback(async () => {
        const _id = user && user._id;
        const userData = {
            _id,
            name,
            email,
            password,
            roles: userRoles,
        }

        await dispatch(saveUser(userData));

    }, [name, email, dispatch, user, password, userRoles]);

    const getFieldError = useCallback((field) => {
        if (!savingUserError) {
            return null;
        }
        const fieldError = savingUserError.find(x => x.field === field);
        return fieldError && fieldError.message;
    }, [savingUserError]);

    return (
        <div>
            <Snackbar open={isToastOpen} autoHideDuration={6000} onClose={handleCloseToast}>
                <Alert onClose={handleCloseToast} severity="error" alertTitle="Error saving role">
                    {savingUserError && savingUserError.message}
                </Alert>
            </Snackbar>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth="sm"
            >
                <DialogTitle id="form-dialog-title">{isNew}</DialogTitle>
                <DialogContent>
                    {/*<DialogContentText>*/}
                    {/*    User will be able to change the password after registration*/}
                    {/*</DialogContentText>*/}
                    <FormControl className={classes.formControl}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Name"
                            fullWidth
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                            error={!!getFieldError('name')}
                            helperText={getFieldError('name') || undefined}
                            autoComplete="false"
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="email"
                            label="Email"
                            fullWidth
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            error={!!getFieldError('email')}
                            helperText={getFieldError('email') || undefined}
                            type="email"
                            autoComplete="false"
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="password"
                            label="Password"
                            fullWidth
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            error={!!getFieldError('password')}
                            helperText={getFieldError('password') || undefined}
                            type="password"
                            autoComplete="false"
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-mutiple-chip-label">Roles</InputLabel>
                        <Select
                            className={classes.multipleSelect}
                            labelId="mutiple-chip-label"
                            id="mutiple-chip"
                            value={userRoles}
                            error={!!getFieldError('roles')}
                            helperText={getFieldError('roles') || undefined}
                            onChange={handleChange}
                            input={<Input id="select-multiple-chip"/>}
                            MenuProps={MenuProps}
                        >
                            {roles.map((role) => (
                                <MenuItem key={role.alias} value={role.alias} name={role.alias}
                                          style={getStyles(role, roles, theme)}>
                                    {role.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <ButtonError
                        onClick={handleClose}
                        variant="contained"
                        disabled={isSavingUser}
                        startIcon={isSavingUser && <LoadingIcon/>}
                    >
                        Cancel
                    </ButtonError>
                    <ButtonSuccess
                        onClick={onSaveUser}
                        variant="contained"
                        disabled={isSavingUser}
                        startIcon={isSavingUser && <LoadingIcon/>}
                    >
                        Save
                    </ButtonSuccess>
                </DialogActions>
            </Dialog>
        </div>
    );
}

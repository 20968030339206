// 0. merge new array with current array
// 1. create Set
// 2. use a key for the new Set to have only unique objects in the array
// 3. convert Set to array
export const uniqueArr = (arr, key) => {
    const map = new Map();
    arr.forEach(item => map.set(item[key], item));
    return [...map.values()];
};

export const getVehicleName = ({specs} = {}) => {
    const { year, make, model, bodyStyle } = specs;
    return `${year} ${make} ${model} ${bodyStyle}`;
};

export const renderLeadingZeroNumber = N => N < 10 ? '0' + N : N;

export const keepNumbersOnly = x => x.replace(/\D/g, '');

export const getFixedDecimals = (val, decimalsNum = 2) => typeof val === 'number' ? val.toFixed(decimalsNum) : val;

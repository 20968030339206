import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: 'none',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(1),
        maxWidth: '90vw',
        maxHeight: '90vh',
        '& img': {
            maxWidth: '100%',
            maxHeight: '100%',
        },
        overflow: 'auto',
    },
    closeButton: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        background: 'rgba(220,220,220,.5)',
        '&:hover': {
            background: 'rgba(220,220,220,.75)',
        }
    },
}));

const TransitionsModal = ({ children, isOpen, onOpen, onClose }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(isOpen || false);

    const handleClose = () => {
        setOpen(false);
        if (onClose) {
            onClose();
        }
    };

    return (
        <Modal
            disableEnforceFocus={true}
            disableAutoFocus={true}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            onBackdropClick={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <ClickAwayListener onClickAway={handleClose}>
                    <div className={classes.paper}>
                        <IconButton aria-label="delete" className={classes.closeButton} onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                        {children}
                    </div>
                </ClickAwayListener>
            </Fade>
        </Modal>
    );
};

export default TransitionsModal;

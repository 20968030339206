import React, {useCallback, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import shallowEqual from "react-redux/lib/utils/shallowEqual";

import CssBaseline from "@material-ui/core/CssBaseline";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {Checkbox} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";

import Copyright from "../../components/copyright/Copyright";
import {authUser} from "../../redux/actions/user";
import Paper from '@material-ui/core/Paper';
import LoadingIcon from '../../components/loadingIcon/LoadingIcon';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: 'url(https://source.unsplash.com/collection/329542/)',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        color: "white",
    },
}));

const Login = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isAuthing = useSelector(store => store.authUser.isAuthing, shallowEqual);
    const [email, setEmail] = useState();
    const [pwd, setPwd] = useState();

    const onSubmit = useCallback((e) => {
        e.preventDefault();
        dispatch(authUser(email, pwd));
    }, [email, pwd, dispatch]);

    const textFieldCommonProps = {
        variant: "outlined",
        margin: "normal",
        required: true,
        fullWidth: true,
    };

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Login
                    </Typography>
                    <form className={classes.form} noValidate onSubmit={onSubmit}>
                        <TextField
                            {...textFieldCommonProps}
                            id="email"
                            label="Email address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            {...textFieldCommonProps}
                            type="password"
                            id="password"
                            label="Password"
                            name="password"
                            autoComplete="current-password"
                            onChange={(e) => setPwd(e.target.value)}
                        />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={isAuthing}
                            startIcon={isAuthing && <LoadingIcon/>}
                        >
                            Login
                        </Button>
                        {/*<Grid container>*/}
                        {/*    <Grid item xs>*/}
                        {/*        <Link href="#" variant="body2">*/}
                        {/*            {"Forgot password?"}*/}
                        {/*        </Link>*/}
                        {/*    </Grid>*/}
                        {/*    <Grid item>*/}
                        {/*        <Link href="#" variant="body2">*/}
                        {/*            {"Have no account? Register."}*/}
                        {/*        </Link>*/}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}
                    </form>
                </div>
                <Box mt={8}>
                    <Copyright hideLogout={true} />
                </Box>
            </Grid>
        </Grid>
    )
}

export default Login;

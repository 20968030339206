import React from 'react';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    buttonSuccess: {
        color: theme.palette.getContrastText(theme.palette.success.dark),
        background: theme.palette.success.main,
        '&:hover': {
            background: theme.palette.success.dark,
        },
        '&:active': {
            background: theme.palette.success.dark,
        },
        '&:focus': {
            background: theme.palette.success.dark,
        },
    }
}));

const ButtonSuccess = (props) => {
    const classes = useStyles();
    return <Button {...props} className={classes.buttonSuccess} />;
}

export default ButtonSuccess;

import React, { useCallback, useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import grey from '@material-ui/core/colors/grey';
import { postWithProgress, getFullURL } from '../../../../helpers/apiRequests';
import Grid from '@material-ui/core/Grid';
import CircularProgressWithLabel from '../../../../components/circularProgressWithLabel/circularProgressWithLabel';

const useStyles = makeStyles(() => ({
    imgContainer: {
        // width: 200,
        height: 150,
        background: grey[100],
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        color: grey[400],
        border: `1px solid ${grey[300]}`,
        borderRadius: 4,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: grey[200],
            borderColor: grey[400],
        },
    },
    imgContainerInternal: {
        textAlign: 'center',
    },
    imgContainerInternalProgress: {
        backgroundColor: 'rgba(255, 255, 255, 0.75)'
    }
}));

const SinglePhoto = ({ photo, updateFileObj, vehicleId }) => {
    const cls = useStyles();
    const [localFile, setLocalFile] = useState(null);

    useEffect(() => {
        if (!localFile) {
            setLocalFile(photo);
        }
    }, [photo, localFile]);

    useEffect(() => {
        if (localFile && (localFile.progress === 100 || localFile.progress === -1)) {
            const newLocalFile = { ...localFile };
            newLocalFile.needsUpload = false;
            newLocalFile.isUploading = false;
            newLocalFile.progress = false;
            setLocalFile({ ...newLocalFile });
        }
    }, [localFile]);

    const updateUploadProgress = useCallback((progress, url) => {
        const fileToUpdate = { ...localFile };
        if (fileToUpdate) {
            if (progress) {
                fileToUpdate.progress = progress;
            }
            if (url) {
                fileToUpdate.url = url;
            }
            if (fileToUpdate.url) {
                updateFileObj(fileToUpdate);
            }
        }

        setLocalFile(fileToUpdate);
    }, [localFile, updateFileObj]);

    useEffect(() => {
        if (localFile && !localFile.needsUpload && localFile.progress < 0 && !localFile.url) {
            const formData = new FormData();
            formData.append('vehicleId', vehicleId);
            formData.append('file', localFile.file);
            (async () => {
                // send file to the server
                const { data } = await postWithProgress('/vehicles/upload-photo', formData, (progress) => {
                    // show progress during the upload
                    updateUploadProgress(Math.round(progress));
                });

                // when file uploaded
                if (data && data.location && !localFile.url) {
                    // save publicURL of the file
                    updateUploadProgress(null, data.location);
                }
            })();
        }
    }, [localFile, updateUploadProgress, vehicleId]);

    // "start" file upload to the server
    useEffect(() => {
        if (localFile && localFile.needsUpload) {
            const newLFile = { ...localFile };
            newLFile.needsUpload = false;
            newLFile.isUploading = true;
            setLocalFile(newLFile);
        }
    }, [localFile]);

    // get file's preview picture (content)
    useEffect(() => {
        if (localFile && localFile.file && !localFile.data) {
            const reader = new FileReader();
            reader.readAsDataURL(localFile.file);
            reader.onload = (evt) => {
                localFile.data = evt.target.result;
            }
            reader.onerror = (evt) => {
                console.error('Error reading file', evt);
            }
        }
    }, [localFile]);

    const placeholderPicture = localFile && localFile.data ? localFile.data : 'https://source.unsplash.com/collection/9807548/200x200';
    const fileName = (localFile && ((localFile.file && localFile.file.name) || localFile.name)) || '';
    const photoUrl = localFile ? (localFile.url && getFullURL(localFile.url)) || localFile.data : placeholderPicture;

    return <Grid item>
        {localFile && localFile.progress >= 0 && localFile.progress !== false && !localFile.url && (
            <Grid
                container
                className={cls.imgContainer}
                style={{ backgroundImage: `url(${localFile.data || placeholderPicture})` }}
                key={`offer-file-${localFile.file.name}-internal`}
            >
                <Grid item className={cls.imgContainerInternalProgress} container justify="center" alignContent="center"
                      key={`offer-file-${localFile.file.name}-overlay`}>
                    <Grid item key={`offer-file-${localFile.file.name}-progress-container`}>
                        <CircularProgressWithLabel variant="static" value={localFile.progress}
                                                   key={`offer-file-${localFile.file.name}-progress`}/>
                    </Grid>
                </Grid>
            </Grid>
        )}
        {localFile && !localFile.progress && (
            <Grid
                container
                className={cls.imgContainer}
                style={{ backgroundImage: `url(${photoUrl})` }}
                key={`offer-file-${fileName}-internal`}
            >
            </Grid>
        )}
    </Grid>
};

export default SinglePhoto;

import {ROLE_SAVE, ROLE_SAVE_ERROR_RESET, ROLES_UPDATE} from '../actions/action-types';
import {pendingActionType, successActionType, failureActionType} from './utils';

const initialState = {
    isFetching: false,
    roles: [],
    isSavingRole: null,
    savingRoleError: null,
};

const roles = (state = initialState, action) => {
    switch (action.type) {
        case pendingActionType(ROLES_UPDATE):
            return {
                ...state,
                ...{isFetching: true}
            };
        case successActionType(ROLES_UPDATE):
            return {
                ...state,
                ...{
                    isFetching: false,
                    roles: [...action.payload],
                }
            };
        case failureActionType(ROLES_UPDATE):
            return {
                ...state,
                ...{
                    isFetching: false,
                    roles: [],
                }
            };
        case pendingActionType(ROLE_SAVE):
            return {
                ...state,
                ...{
                    isSavingRole: true,
                    savingRoleError: null,
                }
            };
        case successActionType(ROLE_SAVE):
            return {
                ...state,
                ...{
                    isSavingRole: false,
                    savingRoleError: null,
                }
            };
        case failureActionType(ROLE_SAVE):
            return {
                ...state,
                ...{
                    isSavingRole: false,
                    savingRoleError: action.payload,
                }
            };
        case ROLE_SAVE_ERROR_RESET:
            return {
                ...state,
                ...{
                    isSavingRole: false,
                    savingRoleError: null,
                }
            };
        default:
            return state;
    }
};

export default roles;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import shallowEqual from 'react-redux/lib/utils/shallowEqual';
import { fetchVehicles, updateEditingVehicle, updateVehicles } from '../../redux/actions/vehicle';
import { getVehicleName, getFixedDecimals } from '../../helpers/general';
import { renderDate } from '../../helpers/date';
import throttle from 'lodash/throttle';
import { singleInstanceGet } from '../../helpers/apiRequests';
import Typography from '@material-ui/core/Typography';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import PropTypes from 'prop-types';
import LoadingIcon from '../../components/loadingIcon/LoadingIcon';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

/**
 * <TableCell className={cls.th}>Vehicle</TableCell>
 <TableCell className={cls.th} align="center">Make</TableCell>
 <TableCell className={cls.th} align="center">Date</TableCell>
 <TableCell className={cls.th} align="center">VIN (last 6)</TableCell>
 <TableCell className={cls.th} align="right">Total Cost</TableCell>
 */
const headCells = [
    { id: 'name',     numeric: false, align: 'left', label: 'Vehicle' },
    { id: 'make',     numeric: false, align: 'center', label: 'Make' },
    { id: 'createdAt',numeric: false, align: 'center', label: 'Date' },
    { id: 'vin',      numeric: false, align: 'center', label: 'VIN (last 6)' },
    { id: 'totalCost',numeric: true, align: 'right',  label: 'Total Cost' },
];

function EnhancedTableHead(props) {
    const { classes, order, orderBy, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead style={{fontWeight: 700}}>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={classes.th}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    table: {
        marginTop: '1rem',
    },
    tabs: {},
    search: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    searchInput: {
        marginRight: '1rem',
    },
    searchLocationSelect: {
        minWidth: 150,
        marginRight: '1rem',
        margin: theme.spacing(1),
    },
    selectEmpty: {
        // marginTop: theme.spacing(2),
    },
    selectLabel: {
        background: '#fff',
        padding: '0 5px',
        marginLeft: '-5px',
    },
    th: {
        fontWeight: 'bold',
    },
    vehicleRow: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.background.default
        }
    },
    smallText: {
        fontSize: '90%',
        textAlign: 'center',
    },
    visuallyHidden: {
        // display: 'none',
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

const vehiclesTypesList = ['lot', 'fleet'];
const searchLocationOptions = [
    {
        value: 'all',
        name: 'All'
    },
    {
        value: 'benson',
        name: 'Benson'
    },
    {
        value: 'dunn',
        name: 'Dunn'
    },
    {
        value: 'smithfield',
        name: 'Smithfield'
    },
];

const Main = () => {
    const cls = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const [vehiclesType, setVehiclesType] = useState(vehiclesTypesList[0]);
    const [searchParamsChanged, setSearchParamsChanged] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [searchLocation, setSearchLocation] = useState(searchLocationOptions[0].value);
    const [isArchived, setIsArchived] = useState(false);
    const [vehicles, setVehicles] = useState([]);
    const storedVehicles = useSelector(store => store.vehicles.vehicles, shallowEqual);
    const isFetchingVehicles = useSelector(store => store.vehicles.isFetching, shallowEqual);

    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('createdAt');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const startEditVehicle = useCallback((vehicle) => {
        dispatch(updateEditingVehicle(vehicle));
        history.push(`/vehicles/${vehicle._id}`);
    }, [dispatch, history]);

    useEffect(() => {
        if (!searchParamsChanged && (!storedVehicles || !storedVehicles.length)) {
            dispatch(fetchVehicles());
            setSearchParamsChanged(true);
        }
    }, [dispatch, searchParamsChanged, storedVehicles, vehiclesType]);

    const handleSearchLocationChange = useCallback((event) => {
        setSearchLocation(event.target.value);
    }, []);

    const onSearchTermChanged = useCallback((value) => {
        setSearchValue(value);
        setSearchParamsChanged(true);
    }, []);

    const switchVehiclesType = useCallback((value) => {
        setVehiclesType(value);
        setSearchParamsChanged(true);
    }, []);

    useEffect(() => {
        setVehicles((storedVehicles || []).filter(r => r.specs.category === vehiclesType).map(v => {
            const filledVehicle = {...v};
            filledVehicle.name = getVehicleName(v);
            filledVehicle.make = v.specs.make;
            filledVehicle.year = v.specs.year;
            filledVehicle.vin = (v.specs.vin || '').substr(-6);
            filledVehicle.totalCost = v.cost.totalCost;
            return filledVehicle;
        }));
    }, [storedVehicles, vehiclesType]);

    const fetch = useMemo(
        () =>
            throttle(async (request, callback) => {
                const result = await singleInstanceGet(`/vehicles/`, request);
                if (callback) {
                    callback(result);
                }
            }, 300),
        [],
    );

    useEffect(() => {
        let active = true;
        const requestParams = {
            searchTerm: searchValue,
            searchLocation: searchLocation,
            archived: isArchived,
        };

        fetch(requestParams, (response) => {
            if (active) {
                const newVehicles = (response && response.data && response.data.vehicles) || [];
                dispatch(updateVehicles(newVehicles));
            }
        });

        return () => {
            active = false;
        };
    }, [dispatch, fetch, isArchived, searchLocation, searchValue]);

    const toggleArchived = useCallback(() => {
        setIsArchived(!isArchived);
        setSearchParamsChanged(true);
    }, [isArchived]);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return <Grid container>
        <Grid item xs={12} container>
            <Grid item xs={4}>
                <ButtonGroup size="large" variant="contained" aria-label="large primary button group">
                    <Button
                        color={vehiclesType === 'lot' ? 'primary' : 'default'}
                        onClick={() => switchVehiclesType('lot')}
                    >
                        Lot Vehicles
                    </Button>
                    <Button
                        color={vehiclesType === 'fleet' ? 'primary' : 'default'}
                        onClick={() => switchVehiclesType('fleet')}
                    >
                        Fleet Vehicles
                    </Button>
                </ButtonGroup>
            </Grid>
            <Grid item xs={8} className={cls.search}>
                <TextField
                    className={cls.searchInput}
                    id="outlined-search"
                    label="Search field"
                    type="search"
                    variant="outlined"
                    size="small"
                    value={searchValue}
                    onChange={(e) => onSearchTermChanged(e.target.value)}
                />
                {vehiclesType === 'lot' && (
                    <FormControl className={cls.searchLocationSelect} variant="outlined" size="small">
                        <InputLabel id="demo-simple-select-placeholder-label-label" className={cls.selectLabel}>
                            Location
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-placeholder-label-label"
                            // variant="outlined"
                            value={searchLocation}
                            onChange={handleSearchLocationChange}
                            // displayEmpty
                            className={cls.selectEmpty}
                        >
                            {searchLocationOptions.map(({ value, name }) => (
                                <MenuItem key={`search-location-${value}`} value={value}>{name}</MenuItem>))}
                        </Select>
                    </FormControl>)}
                <Button variant="contained" color={isArchived ? 'secondary' : 'default'} onClick={toggleArchived}>
                    {`Archives: ${isArchived ? 'ON': 'OFF'}`}
                </Button>
            </Grid>
        </Grid>
        <Grid item xs={12} container>
            <TableContainer component={Paper} className={cls.table}>
                <Table aria-label="simple table">
                    <EnhancedTableHead
                        classes={cls}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={vehicles.length}
                    />
                    <TableBody>
                        {isFetchingVehicles && (
                            <Grid container justify="center">
                                <Grid item>
                                    <LoadingIcon/>
                                    Loading vehicles...
                                </Grid>
                            </Grid>
                        )}
                        {stableSort(vehicles, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map( (vehicle, i) => (
                            <TableRow
                                key={`${i}-${vehicle._id}`}
                                className={cls.vehicleRow}
                                onClick={() => startEditVehicle(vehicle)}
                            >
                                <TableCell component="th" scope="row">
                                    {vehicle.name}
                                </TableCell>
                                <TableCell align="center">{vehicle.make}</TableCell>
                                <TableCell align="center">{renderDate(vehicle.createdAt)}</TableCell>
                                <TableCell align="center">{vehicle.vin}</TableCell>
                                <TableCell align="right">${getFixedDecimals(vehicle.totalCost)}</TableCell>
                            </TableRow>
                        ))}
                        {!(vehicles || []).length && (
                            <TableRow>
                                <TableCell colSpan={5}>
                                    <Typography component="div" color="textSecondary" className={cls.smallText}>
                                        There are no vehicles yet...
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container justify="center">
                <Grid item>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 20, 50]}
                        component="div"
                        count={vehicles.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Grid>
            </Grid>
        </Grid>
    </Grid>;
}

export default Main;

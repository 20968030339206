import React, {useCallback, useEffect, useState} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Title from '../../components/title/Title';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add'
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButtonDanger from '../../components/iconButtonDanger/IconButtonDanger';
import ConfirmModal from '../../components/modals/confirm/ConfirmModal';
import {deleteUser, fetchUsers, toggleActive} from '../../redux/actions/users';
import Switch from '@material-ui/core/Switch';
import EditUserModal from './editUserModal/EditUserModal';
import Chip from '@material-ui/core/Chip';
import usePermissions from '../../hooks/usePermissions';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        // overflow: 'auto',
        flexDirection: 'column',
    },
    smallText: {
        fontSize: '90%',
        textAlign: 'center',
    },
    button: {
        color: 'white',
    },
    controlsColumn: {
        minWidth: '200px',
    }
}));

const Users = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const canEdit = usePermissions('any', 'update');
    const storedUsers = useSelector(store => store.users.users, shallowEqual);
    const [users, setUsers] = useState(storedUsers || []);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [editingUser, setEditingUser] = useState(null);
    const [isConfirmDelete, setConfirmDelete] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);
    const [isDeletingUser, setDeletingUser] = useState(false);

    useEffect(() => {
        if (!storedUsers) {
            dispatch(fetchUsers());
        } else {
            setUsers([...storedUsers]);
        }
    }, [storedUsers, dispatch]);

    const openEditModal = useCallback(() => {
        setEditModalIsOpen(true);
    }, []);

    const closeEditModal = useCallback(async () => {
        setEditModalIsOpen(false);
        await dispatch(fetchUsers());
        setEditingUser(null);
    }, [dispatch]);

    const startEditUser = useCallback((user) => {
        setEditingUser(user);
        openEditModal();
    }, [openEditModal]);

    const openDeleteUserConfirmModal = useCallback((user) => {
        setConfirmDelete(true);
        setUserToDelete(user);
    }, []);

    const confirmDeleteUser = useCallback(async (user) => {
        setDeletingUser(true);

        await dispatch(deleteUser({_id: user._id}));

        setDeletingUser(false);
        setConfirmDelete(true);
        setUserToDelete(null);

        await dispatch(fetchUsers());
    }, [dispatch]);

    const cancelDeleteRole = useCallback(() => {
        setDeletingUser(false);
        setConfirmDelete(false);
        setUserToDelete(null);
    }, []);

    const toggleUserActive = useCallback((user) => {
        const newUsers = users.map(u => {
            if (u._id === user._id) {
                const newUser = {...u};
                newUser.isActive = !newUser.isActive;
                dispatch(toggleActive(newUser));
                return  newUser;
            }
            return u;
        });
        setUsers(newUsers);
    }, [users, dispatch]);

    return (
        <Grid item xs={12}>
            <Paper className={classes.paper}>
                <Title>
                    Users
                    <Button
                        disabled={!canEdit}
                        startIcon={<AddIcon/>}
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={openEditModal}
                    >
                        Add user
                    </Button>
                </Title>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name, email</TableCell>
                            <TableCell>Roles</TableCell>
                            <TableCell>Created at</TableCell>
                            <TableCell>Is Active</TableCell>
                            <TableCell align="center" className={classes.controlsColumn}>Controls</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users && users.length ? users.map((user) => (
                            <TableRow key={user._id}>
                                <TableCell>
                                    {user.name}
                                    <br/>
                                    <a href={`mailto:${user.email}`}>{user.email}</a>
                                </TableCell>
                                <TableCell>{user.roles && user.roles.map(role => <Chip
                                    label={role}
                                    color={role === 'editor' ? 'secondary' : 'primary'}
                                />)}</TableCell>
                                <TableCell>{user.createdAt}</TableCell>
                                <TableCell>
                                    <Switch
                                        disabled={!canEdit}
                                        checked={user.isActive}
                                        onChange={() => toggleUserActive(user)}
                                        color="primary"
                                        name="checkedB"
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                </TableCell>
                                <TableCell align="center" className={classes.controlsColumn}>
                                    <IconButton onClick={() => startEditUser(user)} disabled={!canEdit}>
                                        <EditIcon/>
                                    </IconButton>
                                    <IconButtonDanger onClick={() => openDeleteUserConfirmModal(user)} disabled={!canEdit}>
                                        <DeleteIcon/>
                                    </IconButtonDanger>
                                </TableCell>
                            </TableRow>
                        )) : (
                            <TableRow>
                                <TableCell colSpan={4}>
                                    <Typography component="div" color="textSecondary" className={classes.smallText}>
                                        There are no users yet
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Paper>
            {editModalIsOpen && <EditUserModal user={editingUser} _isOpen={editModalIsOpen} closeFunc={closeEditModal} />}
            {userToDelete && (
                <ConfirmModal
                    open={isConfirmDelete}
                    onConfirm={confirmDeleteUser}
                    onClose={cancelDeleteRole}
                    entity={userToDelete}
                    content={`You are going to delete user "${userToDelete.name}". This action is not reversible!`}
                    isPending={isDeletingUser}
                />
            )}
        </Grid>
    )
};

export default Users;

const PREFIX = 'tlcw_';

export const set = (key, value) => {
    const valueStr = typeof value === 'string' ? value : JSON.stringify(value);
    localStorage.setItem(`${PREFIX}_${key}`, valueStr);
};

export const get = (key) => {
    return localStorage.getItem(`${PREFIX}_${key}`);
};

export const del = (key) => {
    return localStorage.removeItem(`${PREFIX}_${key}`);
};


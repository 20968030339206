import { get, post } from '../../../helpers/apiRequests';
import { failureActionType, pendingActionType, successActionType } from '../../reducers/utils';
import {
    EDITING_VEHICLE_CLEAR, EDITING_VEHICLE_FETCH,
    EDITING_VEHICLE_UPDATED,
    VEHICLE_SAVE,
    VEHICLE_SAVE_ERROR_RESET,
    VEHICLES_UPDATE
} from '../action-types';

export const fetchingVehicles = () => ({
    type: pendingActionType(VEHICLES_UPDATE),
    payload: null,
});

export const updateVehicles = (data) => ({
    type: successActionType(VEHICLES_UPDATE),
    payload: data,
});

export const failureFetchVehicles = (data) => ({
    type: failureActionType(VEHICLES_UPDATE),
    payload: null,
});

export const fetchVehicles = () => async dispatch => {
    dispatch(fetchingVehicles());
    try {
        const json = await get('/vehicles');
        const { data } = json || {};
        dispatch(updateVehicles(data && data.vehicles));
    } catch (err) {
        dispatch(failureFetchVehicles(err));
    }
};



export const fetchingVehicleById = () => ({
    type: pendingActionType(EDITING_VEHICLE_FETCH),
    payload: null,
});

export const updateVehicleById = (data) => ({
    type: successActionType(EDITING_VEHICLE_FETCH),
    payload: data,
});

export const failureFetchVehicleById = (data) => ({
    type: failureActionType(EDITING_VEHICLE_FETCH),
    payload: data,
});

export const fetchVehicleById = (vehicleId) => async dispatch => {
    const url = `/vehicles/${vehicleId}`;
    dispatch(fetchingVehicleById());
    try {
        const json = await get(url);
        const { data } = json || {};
        dispatch(updateVehicleById(data && data.vehicle));
    } catch (err) {
        dispatch(failureFetchVehicleById(err));
    }
};

export const updateEditingVehicleField = (field, value) => ({
    type: EDITING_VEHICLE_UPDATED,
    payload: {
        [field]: value,
    },
});

export const updateEditingVehicle = (data) => ({
    type: EDITING_VEHICLE_UPDATED,
    payload: data,
});

export const clearEditingVehicle = () => ({
    type: EDITING_VEHICLE_CLEAR,
    payload: null,
});

export const savingVehicle = () => ({
    type: pendingActionType(VEHICLE_SAVE),
    payload: null,
});

export const savedVehicle = (data) => ({
    type: successActionType(VEHICLE_SAVE),
    payload: data,
});

export const failureSavingVehicle = (data) => ({
    type: failureActionType(VEHICLE_SAVE),
    payload: data,
});

export const saveVehicle = (data) => async dispatch => {
    const dataToSave = { ...data };
    const isNew = !dataToSave._id;
    const url = '/vehicles' + (isNew ? '' : `/${dataToSave._id}`);

    dispatch(savingVehicle());
    try {
        const json = await post(url, { ...dataToSave });
        const { data: { vehicle } } = json || {};
        dispatch(savedVehicle(vehicle));
        return vehicle;
    } catch (err) {
        const { response = {} } = err;
        const { data = err } = response;
        dispatch(failureSavingVehicle(data));
    }
};

export const resetEditingVehicle = () => ({
    type: VEHICLE_SAVE_ERROR_RESET,
    payload: null,
});

export const toggleArchived = (data) => async dispatch => {
    const url = `/vehicles/${data._id}/toggleArchived`;

    dispatch(savingVehicle());
    try {
        const json = await post(url, {});
        const { data } = json || {};
        dispatch(savedVehicle(data.vehicle || {}));
    } catch (err) {
        dispatch(failureSavingVehicle(err));
    }
};

import {USER_SAVE, USER_SAVE_ERROR_RESET, USERS_UPDATE} from '../actions/action-types';
import {pendingActionType, successActionType, failureActionType} from './utils';

const initialState = {
    isFetching: false,
    users: null,
    isSavingUser: null,
    savingUserError: null,
};

const users = (state = initialState, action) => {
    switch (action.type) {
        case pendingActionType(USERS_UPDATE):
            return {
                ...state,
                ...{isFetching: true}
            };
        case successActionType(USERS_UPDATE):
            return {
                ...state,
                ...{
                    isFetching: false,
                    users: [...action.payload],
                }
            };
        case failureActionType(USERS_UPDATE):
            return {
                ...state,
                ...{
                    isFetching: false,
                    users: null,
                }
            };
        case pendingActionType(USER_SAVE):
            return {
                ...state,
                ...{
                    isSavingUser: true,
                    savingUserError: null,
                }
            };
        case successActionType(USER_SAVE):
            return {
                ...state,
                ...{
                    isSavingUser: false,
                    savingUserError: null,
                }
            };
        case failureActionType(USER_SAVE):
            return {
                ...state,
                ...{
                    isSavingUser: false,
                    savingUserError: action.payload,
                }
            };
        case USER_SAVE_ERROR_RESET:
            return {
                ...state,
                ...{
                    isSavingUser: false,
                    savingUserError: null,
                }
            };
        default:
            return state;
    }
};

export default users;

import {get as LSGet, set as LSSet} from '../../../helpers/localStorage';
import {AUTH_USER_LOGOUT, AUTH_USER_UPDATED} from '../action-types';
import {successActionType, pendingActionType, failureActionType} from '../../reducers/utils';
import {post} from '../../../helpers/apiRequests';

export const updateAuthUser = data => ({
    type: successActionType(AUTH_USER_UPDATED),
    payload: data || {},
});
export const authUserInProgress = () => ({
    type: pendingActionType(AUTH_USER_UPDATED),
    payload: null,
})
export const errorAuthUser = data => ({
    type: failureActionType(AUTH_USER_UPDATED),
    payload: data || {},
});

export const tryReAuth = () => async dispatch => {
    const token = LSGet('user_token');
    if(!token) {
        return null;
    }
    dispatch(authUserInProgress());
    try {
        const json = await post('/user/re-auth', {token});
        const {data} = json || {};
        dispatch(updateAuthUser(data));
    } catch (err) {
        dispatch(errorAuthUser(err));
    }
}

export const authUser = (email, password) => async dispatch => {
    dispatch(authUserInProgress());
    try {
        const json = await post('/user/auth', {email, password});
        const {data} = json || {};
        const {token} = data || {};
        dispatch(updateAuthUser(data));
        if (token) {
            LSSet('user_token', token);
        }
    } catch (err) {
        dispatch(errorAuthUser(err));
    }
};

export const logOut = () => ({
    type: AUTH_USER_LOGOUT,
    payload: null,
});

import {shallowEqual, useSelector} from 'react-redux';

const permissions = {read: true, create: true, update: true, delete: true};

const usePermissions = (entity, action = 'read') => {
    if (!entity) {
        throw new Error('Unknown entity to check permissions for');
    }
    // check if action is in the allowed list
    if (!permissions[action]) {
        throw new Error('Unknown action to check permissions for');
    }

    const currentUser = useSelector(store => store.authUser.authedUser.user, shallowEqual);

    // const {roles} = currentUser && {};
    // const [parsedPermissions, setParsedPermissions] = useState({});
    if (entity === 'user' && action !== 'read') {
        return currentUser && currentUser.isAdmin;
    }
    if (currentUser && currentUser.isAdmin) {
        return true;
    }

    return currentUser.roles && currentUser.roles.indexOf('editor') !== -1;
}

export default usePermissions;

import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Repairs from './repairs/Repairs';
import Photos from './photos/Photos';
import { renderDate } from '../../helpers/date';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
    clearEditingVehicle,
    fetchVehicleById,
    fetchVehicles,
    saveVehicle,
    toggleArchived,
    updateEditingVehicle, updateEditingVehicleField
} from '../../redux/actions/vehicle';
import { useParams } from 'react-router-dom';
import LoadingIcon from '../../components/loadingIcon/LoadingIcon';
import usePrevious from '../../hooks/usePrevious';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '../../components/alert/Alert';
import usePermissions from '../../hooks/usePermissions';
import { useHistory } from 'react-router-dom';
import { resetSaveVehicleError } from '../../redux/actions/repairs';
import { getFixedDecimals } from '../../helpers/general';

const { REACT_APP_API_DOMAIN: API_DOMAIN } = process.env;

const useStyles = makeStyles((theme) => ({
    hidden: {
        display: 'none',
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    textInput: {
        margin: theme.spacing(1),
    },
    error: {
        borderColor: theme.palette.error.main + ' !important',
        color: theme.palette.error.main + ' !important',
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    bottomButtonsContainer: {
        marginTop: theme.spacing(2),
    },
    deleteButton: {
        color: theme.palette.error.dark,
    },
    editButton: {
        color: theme.palette.warning.dark,
    },
    successButton: {
        color: theme.palette.success.dark,
    },
    repairRow: {
        cursor: 'pointer',
    },
    tabsNavigation: {
        marginBottom: theme.spacing(1),
    }
}));

const tabs = [
    {
        name: 'Details',
        alias: 'details',
    },
    {
        name: 'Repairs',
        alias: 'repairs',
    },
    {
        name: 'Photos',
        alias: 'photos',
    },
];

const REQUIRED_SPECS_FIELDS = {
    vin: true,
    year: true,
    make: true,
    model: true,
};

const REQUIRED_ERR_STR = 'Field is required';

const AddVehicle = () => {
    const emptyVehicle = {
        specs: {
            category: 'lot',
            location: 'benson',
            stockNumber: '',
            vin: '',
            tag: '',
            year: '',
            make: '',
            model: '',
            bodyStyle: '',
            bodyType: '',
            colorExt: '',
            colorInt: '',
            cylinder: 4,
            transmission: 'automatic',
            engineSize: '',
            purchaseMiles: '',
            currentMiles: '',
            driveTrain: 'FWD',
            vehicleWT: '',
            gvw: '',
            fuelType: 'Gasoline',
            purFrom: '',
            gpsInstalledBy: '?',
            spareKey: true,
        },
        cost: {
            purchaseFrom: '',
            purchaseDate: new Date(2020, 5, 27),
            originalCost: '',
            repairCostWS: '',
            repairCostRT: '',
            totalProfit: '',
            totalCost: '',
            sellingPrice: '',
            downPayment: '',
            minimumPrice: '',
        },
        isArchived: true,
    };

    const cls = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const canEdit = usePermissions('any', 'update');
    const [curTab, setCurTab] = useState(tabs[0]);
    const isTabVisible = useCallback((alias = '') => curTab && curTab.alias === alias, [curTab]);

    const switchTab = useCallback((selectedTabAlias) => {
        setCurTab(tabs.find(tab => tab.alias === selectedTabAlias));
    }, []);

    const { vehicleId } = useParams();
    const isSavingVehicle = useSelector(store => store.vehicles.isSavingVehicle, shallowEqual);
    const savingVehicleError = useSelector(store => store.vehicles.savingVehicleError, shallowEqual);
    const prevIsSavingVehicle = usePrevious(isSavingVehicle);
    const [isToastOpen, setToastIsOpen] = useState(false);
    const [isSuccessToastOpen, setSuccessToastIsOpen] = useState(false);
    const [invalidFields, setInvalidFields] = useState({});

    const handleCloseToast = useCallback(() => {
        setToastIsOpen(false);
        dispatch(resetSaveVehicleError());
    }, [dispatch]);

    const handleCloseSuccessToast = useCallback(() => {
        setSuccessToastIsOpen(false);
    }, []);

    // clear editingVehicle when dismounted
    useEffect(() => {
        return () => {
            dispatch(clearEditingVehicle());
            setStorageUsed(false);
            setCurrentVehicle({ ...emptyVehicle });
        }
    }, [dispatch]);

    useEffect(() => {
        if (prevIsSavingVehicle === true && isSavingVehicle === false) {
            // do we need to show error?
            if (savingVehicleError) {
                setToastIsOpen(true);
            } else {
                setSuccessToastIsOpen(true);
            }
        }
    }, [isSavingVehicle, prevIsSavingVehicle, savingVehicleError]);

    const [storageUsed, setStorageUsed] = useState(false);
    const storedEditingVehicle = useSelector(store => store.vehicles.editingVehicle, shallowEqual);
    const [currentVehicle, setCurrentVehicle] = useState({ ...emptyVehicle });

    const [vehicles, setVehicles] = useState([]);
    const storedVehicles = useSelector(store => store.vehicles.vehicles, shallowEqual);

    useEffect(() => {
        if (storedVehicles && storedVehicles.length) {
            setVehicles([...storedVehicles]);
        } else {
            dispatch(fetchVehicles());
        }
    }, [dispatch, storedVehicles]);

    // check if we have offerId in params but currently editingVehicle is not the needed one
    useEffect(() => {
        const storedVehicleHasNeededId = storedEditingVehicle && storedEditingVehicle._id && storedEditingVehicle._id === vehicleId;
        if (vehicleId && vehicleId !== 'new' && !storedVehicleHasNeededId) {
            const neededVehicle = vehicles.find(vehicle => vehicle._id === vehicleId);

            if (!neededVehicle) {
                dispatch(fetchVehicleById(vehicleId));
            } else {
                updateEditingVehicle(neededVehicle);
                setCurrentVehicle(neededVehicle);
            }
        }
    }, [dispatch, vehicleId, storedEditingVehicle, vehicles]);

    useEffect(() => {
        if (!storageUsed && storedEditingVehicle) {
            setCurrentVehicle({ ...storedEditingVehicle });
            // setStorageUsed(true);
        }
    }, [storageUsed, storedEditingVehicle]);

    const onSaveVehicle = useCallback(async (vehicleToSave = currentVehicle) => {
        if (!vehicleToSave || !vehicleToSave.specs) {
            // vehicleToSave.specs = emptyVehicle.specs;
            return Promise.resolve();
        }
        //check for required fields
        const errors = [];
        Object.keys(vehicleToSave.specs).forEach((key) => {
            const val = vehicleToSave.specs[key];
            const isEmpty = typeof val === 'string' && (!val || !val.trim());
            if (REQUIRED_SPECS_FIELDS[key] && isEmpty) {
                errors.push({
                    field: key,
                    message: REQUIRED_ERR_STR,
                });
            } else if (key === 'engineSize' && isEmpty && vehicleId && vehicleId !== 'new') {
                errors.push({
                    field: key,
                    message: REQUIRED_ERR_STR,
                });
            }
        });

        if (errors.length) {
            setInvalidFields(errors.reduce((accum, { field, message }) => {
                accum[field] = message;
                return accum;
            }, {}))
        }

        if(!Object.values(invalidFields).length && !errors.length) {
            const newVehicle = await dispatch(saveVehicle(vehicleToSave));
            dispatch(fetchVehicles());
            if (newVehicle && newVehicle._id) {
                history.push(`/vehicles/${newVehicle._id}`);
            }
        }
    }, [currentVehicle, dispatch, history, invalidFields, vehicleId]);

    const onToggleVehicleArchived = useCallback(async (vehicleToSave = currentVehicle) => {
        await dispatch(toggleArchived(vehicleToSave));
        // dispatch(fetchVehicles());
    }, [currentVehicle, dispatch]);

    const updateVehicleFieldCost = useCallback((field, value, vehicle = null) => {
        const newVehicle = { ...(vehicle || currentVehicle) };
        if (!newVehicle.cost) {
            newVehicle.cost = { ...emptyVehicle.cost };
        }
        if (newVehicle.cost[field] !== value) {
            newVehicle.cost[field] = value;
            setCurrentVehicle(newVehicle);
        }
    }, [currentVehicle]);

    const updateVehicleFieldSpecs = useCallback((field, value) => {
        const newVehicle = { ...currentVehicle };
        if (!newVehicle.specs) {
            newVehicle.specs = { ...emptyVehicle.specs };
        }
        if (newVehicle.specs[field] !== value) {
            newVehicle.specs[field] = value;
            setCurrentVehicle(newVehicle);
            if (invalidFields[field]) {
                const newInvalidFields = { ...invalidFields };
                delete newInvalidFields[field];
                setInvalidFields(newInvalidFields);
            }
        }
        if (field === 'purFrom') {
            updateVehicleFieldCost('purchaseFrom', value, newVehicle);
        }
    }, [currentVehicle, invalidFields, updateVehicleFieldCost]);

    const updateVehiclePhotos = useCallback((photos = [], saveVehicle = false) => {
        const newVehicle = { ...currentVehicle };
        newVehicle.photos = [...photos];
        setCurrentVehicle(newVehicle);
        dispatch(updateEditingVehicleField('photos', [...photos]));
        if (saveVehicle) {
            onSaveVehicle(newVehicle);
        }
    }, [currentVehicle, dispatch, onSaveVehicle]);

    const onMakePDF = useCallback(async () => {
        const url = `${API_DOMAIN}/api/vehicles/${vehicleId}/exportToPDF`
        window.open(url, '_blank');
    }, [vehicleId])

    const { specs: defaultSpecs, cost: defaultCost } = { ...emptyVehicle };
    const { specs = defaultSpecs, cost = defaultCost } = currentVehicle || { ...emptyVehicle };

    return <Grid container>
        <Snackbar open={isToastOpen} autoHideDuration={6000} onClose={handleCloseToast}>
            <Alert onClose={handleCloseToast} severity="error" alertTitle="Failed to save">
                {savingVehicleError && savingVehicleError.message}
            </Alert>
        </Snackbar>
        <Snackbar open={isSuccessToastOpen} autoHideDuration={6000} onClose={handleCloseSuccessToast}>
            <Alert onClose={handleCloseToast} severity="success" alertTitle="Vehicle has been saved!">
                {/*Vehicle has been saved!*/}
            </Alert>
        </Snackbar>
        <Grid item xs={12} container className={cls.tabsNavigation}>
            <Grid item xs={4}>
                <ButtonGroup size="large" variant="contained" aria-label="large primary button group">
                    {tabs.map(({ name, alias }) => (<Button
                        key={`tab-${alias}`}
                        color={curTab.alias === alias ? 'primary' : 'default'}
                        onClick={() => switchTab(alias)}
                        disabled={!currentVehicle || (!currentVehicle._id && alias !== 'details')}
                    >
                        {name}
                    </Button>))}
                </ButtonGroup>
            </Grid>
        </Grid>
        <Grid item xs={12} container spacing={1} className={classNames({ [cls.hidden]: !isTabVisible('details') })}>
            {isTabVisible('details') && (
                <>
                    <Grid item xs={6}>
                        <Paper className={cls.paper}>
                            <Typography variant="h6" component="h6">
                                Vehicle Specs
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <FormControl variant="outlined" fullWidth className={cls.formControl} size="small"
                                                 disabled={!canEdit}>
                                        <InputLabel id="demo-simple-select-outlined-label">Category</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={specs.category}
                                            onChange={(e) => updateVehicleFieldSpecs('category', e.target.value)}
                                            label="Category"
                                        >
                                            <MenuItem value="lot">Lot</MenuItem>
                                            <MenuItem value="fleet">Fleet</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {specs.category !== 'fleet' && (
                                        <FormControl variant="outlined" fullWidth className={cls.formControl}
                                                     size="small"
                                                     disabled={!canEdit}>
                                            <InputLabel id="demo-simple-select-outlined-label">Location</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                value={specs.location}
                                                onChange={(e) => updateVehicleFieldSpecs('location', e.target.value)}
                                                label="Location"
                                            >
                                                <MenuItem value="benson">Benson</MenuItem>
                                                <MenuItem value="dunn">Dunn</MenuItem>
                                                <MenuItem value="smithfield">Smithfield</MenuItem>
                                            </Select>
                                        </FormControl>
                                    )}
                                    <TextField
                                        fullWidth
                                        disabled={!canEdit}
                                        className={cls.textInput}
                                        label="Stock #"
                                        type="number"
                                        variant="outlined"
                                        size="small"
                                        value={specs.stockNumber}
                                        onChange={(e) => updateVehicleFieldSpecs('stockNumber', e.target.value)}
                                    />
                                    <TextField
                                        fullWidth
                                        disabled={!canEdit}
                                        error={!!invalidFields.vin}
                                        helperText={invalidFields.vin || undefined}
                                        className={cls.textInput}
                                        label="VIN"
                                        type="text"
                                        variant="outlined"
                                        size="small"
                                        value={specs.vin}
                                        onChange={(e) => updateVehicleFieldSpecs('vin', e.target.value)}
                                    />
                                    <TextField
                                        fullWidth
                                        disabled={!canEdit}
                                        className={cls.textInput}
                                        label="Tag"
                                        type="text"
                                        variant="outlined"
                                        size="small"
                                        value={specs.tag}
                                        onChange={(e) => updateVehicleFieldSpecs('tag', e.target.value)}
                                    />
                                    <TextField
                                        fullWidth
                                        disabled={!canEdit}
                                        error={!!invalidFields.year}
                                        helperText={invalidFields.year || undefined}
                                        className={cls.textInput}
                                        label="Year"
                                        type="number"
                                        variant="outlined"
                                        size="small"
                                        value={specs.year}
                                        onChange={(e) => updateVehicleFieldSpecs('year', e.target.value)}
                                    />
                                    <TextField
                                        fullWidth
                                        disabled={!canEdit}
                                        error={!!invalidFields.make}
                                        helperText={invalidFields.make || undefined}
                                        className={cls.textInput}
                                        label="Make"
                                        type="text"
                                        variant="outlined"
                                        size="small"
                                        value={specs.make}
                                        onChange={(e) => updateVehicleFieldSpecs('make', e.target.value)}
                                    />
                                    <TextField
                                        fullWidth
                                        disabled={!canEdit}
                                        error={!!invalidFields.model}
                                        helperText={invalidFields.model || undefined}
                                        className={cls.textInput}
                                        label="Model"
                                        type="text"
                                        variant="outlined"
                                        size="small"
                                        value={specs.model}
                                        onChange={(e) => updateVehicleFieldSpecs('model', e.target.value)}
                                    />
                                    <TextField
                                        fullWidth
                                        disabled={!canEdit}
                                        className={cls.textInput}
                                        label="Body style"
                                        type="text"
                                        variant="outlined"
                                        size="small"
                                        value={specs.bodyStyle}
                                        onChange={(e) => updateVehicleFieldSpecs('bodyStyle', e.target.value)}
                                    />
                                    <TextField
                                        fullWidth
                                        disabled={!canEdit}
                                        className={cls.textInput}
                                        label="Trim level"
                                        type="text"
                                        variant="outlined"
                                        size="small"
                                        value={specs.bodyType}
                                        onChange={(e) => updateVehicleFieldSpecs('bodyType', e.target.value)}
                                    />
                                    <TextField
                                        fullWidth
                                        disabled={!canEdit}
                                        className={cls.textInput}
                                        label="Color Ext"
                                        type="text"
                                        variant="outlined"
                                        size="small"
                                        value={specs.colorExt}
                                        onChange={(e) => updateVehicleFieldSpecs('colorExt', e.target.value)}
                                    />
                                    <TextField
                                        fullWidth
                                        disabled={!canEdit}
                                        className={cls.textInput}
                                        label="Color Int"
                                        type="text"
                                        variant="outlined"
                                        size="small"
                                        value={specs.colorInt}
                                        onChange={(e) => updateVehicleFieldSpecs('colorInt', e.target.value)}
                                    />
                                    <TextField
                                        fullWidth
                                        disabled={!canEdit}
                                        className={cls.textInput}
                                        label="Cylinder"
                                        type="number"
                                        variant="outlined"
                                        size="small"
                                        value={specs.cylinder}
                                        onChange={(e) => updateVehicleFieldSpecs('cylinder', e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl variant="outlined" fullWidth className={cls.formControl} size="small"
                                                 disabled={!canEdit}>
                                        <InputLabel id="demo-simple-select-outlined-label">Transmission</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            value={specs.transmission}
                                            onChange={(e) => updateVehicleFieldSpecs('transmission', e.target.value)}
                                            label="Transmission"
                                        >
                                            <MenuItem value="automanual">Automanual</MenuItem>
                                            <MenuItem value="automatic">Automatic</MenuItem>
                                            <MenuItem value="CVT">CVT</MenuItem>
                                            <MenuItem value="manual">Manual</MenuItem>
                                            <MenuItem value="other">Other / Unknown</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        fullWidth
                                        disabled={!canEdit}
                                        error={!!invalidFields.engineSize}
                                        helperText={invalidFields.engineSize || undefined}
                                        className={cls.textInput}
                                        label="Engine size"
                                        type="text"
                                        variant="outlined"
                                        size="small"
                                        value={specs.engineSize}
                                        onChange={(e) => updateVehicleFieldSpecs('engineSize', e.target.value)}
                                    />
                                    <TextField
                                        fullWidth
                                        disabled={!canEdit}
                                        className={cls.textInput}
                                        label="Purchase miles"
                                        type="number"
                                        variant="outlined"
                                        size="small"
                                        value={specs.purchaseMiles}
                                        onChange={(e) => updateVehicleFieldSpecs('purchaseMiles', e.target.value)}
                                    />
                                    <TextField
                                        fullWidth
                                        disabled={!canEdit}
                                        className={cls.textInput}
                                        label="Current miles"
                                        type="number"
                                        variant="outlined"
                                        size="small"
                                        value={specs.currentMiles}
                                        onChange={(e) => updateVehicleFieldSpecs('currentMiles', e.target.value)}
                                    />
                                    <FormControl variant="outlined" fullWidth className={cls.formControl} size="small"
                                                 disabled={!canEdit}>
                                        <InputLabel id="demo-simple-select-outlined-label">Drive train</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            value={specs.driveTrain}
                                            onChange={(e) => updateVehicleFieldSpecs('driveTrain', e.target.value)}
                                            label="Drive train"
                                        >
                                            <MenuItem value="4x2/2-wheel drive">4x2/2-wheel drive</MenuItem>
                                            <MenuItem value="4x4/4-wheel drive">4x4/4-wheel drive</MenuItem>
                                            <MenuItem value="AWD">AWD</MenuItem>
                                            <MenuItem value="FWD">FWD</MenuItem>
                                            <MenuItem value="RWD">RWD</MenuItem>
                                            <MenuItem value="Unknown">Unknown</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {/*<TextField*/}
                                    {/*    fullWidth*/}
                                    {/*    disabled={!canEdit}*/}
                                    {/*    className={cls.textInput}*/}
                                    {/*    label="Vehicle WT"*/}
                                    {/*    type="number"*/}
                                    {/*    variant="outlined"*/}
                                    {/*    size="small"*/}
                                    {/*    value={specs.vehicleWT}*/}
                                    {/*    onChange={(e) => updateVehicleFieldSpecs('vehicleWT', e.target.value)}*/}
                                    {/*/>*/}
                                    <TextField
                                        fullWidth
                                        disabled={!canEdit}
                                        className={cls.textInput}
                                        label="GVW"
                                        type="number"
                                        variant="outlined"
                                        size="small"
                                        value={specs.gvw}
                                        onChange={(e) => updateVehicleFieldSpecs('gvw', e.target.value)}
                                    />
                                    <FormControl variant="outlined" fullWidth className={cls.formControl} size="small"
                                                 disabled={!canEdit}>
                                        <InputLabel id="demo-simple-select-outlined-label">Fuel type</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            value={specs.fuelType}
                                            onChange={(e) => updateVehicleFieldSpecs('fuelType', e.target.value)}
                                            label="Fuel type"
                                        >
                                            <MenuItem value="Gasoline">Gasoline</MenuItem>
                                            <MenuItem value="Diesel">Diesel</MenuItem>
                                            <MenuItem value="Hybrid">Hybrid</MenuItem>
                                            <MenuItem value="Compressed Natural Gas">Compressed Natural Gas</MenuItem>
                                            <MenuItem value="E85 Flex Fuel">E85 Flex Fuel</MenuItem>
                                            <MenuItem value="Unknown">Unknown</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        fullWidth
                                        disabled={!canEdit}
                                        className={cls.textInput}
                                        label="Purchase from"
                                        type="text"
                                        variant="outlined"
                                        size="small"
                                        value={specs.purFrom}
                                        onChange={(e) => updateVehicleFieldSpecs('purFrom', e.target.value)}
                                    />
                                    <TextField
                                        fullWidth
                                        disabled={!canEdit}
                                        className={cls.textInput}
                                        label="GPS Installed by"
                                        type="text"
                                        variant="outlined"
                                        size="small"
                                        value={specs.gpsInstalledBy}
                                        onChange={(e) => updateVehicleFieldSpecs('gpsInstalledBy', e.target.value)}
                                    />
                                    <FormControl variant="outlined" fullWidth className={cls.formControl} size="small"
                                                 disabled={!canEdit}>
                                        <InputLabel id="demo-simple-select-outlined-label">Spare key</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            value={specs.spareKey}
                                            onChange={(e) => updateVehicleFieldSpecs('spareKey', e.target.value)}
                                            label="Spare key"
                                        >
                                            <MenuItem value={true}>Yes</MenuItem>
                                            <MenuItem value={false}>No</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper className={cls.paper}>
                            <Typography variant="h6" component="h6">
                                Vehicle Cost
                            </Typography>
                            <TextField
                                fullWidth
                                disabled={!canEdit}
                                className={cls.textInput}
                                label="Purchase from"
                                type="text"
                                variant="outlined"
                                size="small"
                                value={cost.purchaseFrom}
                                onChange={(e) => updateVehicleFieldSpecs('purFrom', e.target.value)}
                            />
                            <TextField
                                fullWidth
                                disabled={!canEdit}
                                className={cls.textInput}
                                label="Purchase date"
                                type="date"
                                variant="outlined"
                                size="small"
                                InputProps={{ inputProps: { max: renderDate(Date.now(), 'YYYY-MM-DD') } }}
                                value={renderDate(cost.purchaseDate, 'YYYY-MM-DD')}
                                onChange={(e) => updateVehicleFieldCost('purchaseDate', e.target.value)}
                            />
                            <FormControl fullWidth className={cls.textInput} variant="outlined" size="small"
                                         disabled={!canEdit}>
                                <InputLabel htmlFor="outlined-adornment-amount">Original cost</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-amount"
                                    type="number"
                                    value={getFixedDecimals(cost.originalCost)}
                                    onChange={(e) => updateVehicleFieldCost('originalCost', e.target.value)}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    labelWidth={95}
                                />
                            </FormControl>
                            <FormControl fullWidth className={cls.textInput} variant="outlined" size="small" disabled>
                                <InputLabel htmlFor="outlined-adornment-amount">Repair cost WS</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-amount"
                                    type="number"
                                    value={getFixedDecimals(cost.repairCostWS)}
                                    // onChange={handleChange('amount')}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    labelWidth={115}
                                />
                            </FormControl>
                            <FormControl fullWidth className={cls.textInput} variant="outlined" size="small" disabled>
                                <InputLabel htmlFor="outlined-adornment-amount">Repair cost RT</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-amount"
                                    type="number"
                                    value={getFixedDecimals(cost.repairCostRT)}
                                    // onChange={handleChange('amount')}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    labelWidth={110}
                                />
                            </FormControl>
                            <FormControl fullWidth className={cls.textInput} variant="outlined" size="small">
                                <InputLabel htmlFor="outlined-adornment-amount">Total profit</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-amount"
                                    type="number"
                                    value={getFixedDecimals(cost.totalProfit)}
                                    onChange={(e) => updateVehicleFieldCost('totalProfit', e.target.value)}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    labelWidth={80}
                                />
                            </FormControl>
                            <FormControl fullWidth className={cls.textInput} variant="outlined" size="small" disabled>
                                <InputLabel htmlFor="outlined-adornment-amount">Total cost</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-amount"
                                    type="number"
                                    value={getFixedDecimals(cost.totalCost)}
                                    // onChange={handleChange('amount')}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    labelWidth={75}
                                />
                            </FormControl>
                            <Divider variant="middle" className={cls.divider}/>
                            <FormControl fullWidth className={cls.textInput} variant="outlined" size="small"
                                         disabled={!canEdit}>
                                <InputLabel htmlFor="outlined-adornment-amount">Selling Price</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-amount"
                                    type="number"
                                    value={getFixedDecimals(cost.sellingPrice)}
                                    onChange={(e) => updateVehicleFieldCost('sellingPrice', e.target.value)}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    labelWidth={95}
                                />
                            </FormControl>
                            <FormControl fullWidth className={cls.textInput} variant="outlined" size="small"
                                         disabled={!canEdit}>
                                <InputLabel htmlFor="outlined-adornment-amount">Down payment</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-amount"
                                    type="number"
                                    value={getFixedDecimals(cost.downPayment)}
                                    onChange={(e) => updateVehicleFieldCost('downPayment', e.target.value)}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    labelWidth={110}
                                />
                            </FormControl>
                            <FormControl fullWidth className={cls.textInput} variant="outlined" size="small"
                                         disabled={!canEdit}>
                                <InputLabel htmlFor="outlined-adornment-amount">Minimum Payment</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-amount"
                                    type="number"
                                    value={getFixedDecimals(cost.minimumPrice)}
                                    onChange={(e) => updateVehicleFieldCost('minimumPrice', e.target.value)}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    labelWidth={140}
                                />
                            </FormControl>
                        </Paper>
                        {canEdit && (
                            <Grid container spacing={1} className={cls.bottomButtonsContainer}>
                                <Grid item xs={5} container justify="center" onClick={() => onSaveVehicle(currentVehicle)}>
                                    <Button variant="contained" color="primary" size="large" disabled={isSavingVehicle}>
                                        {isSavingVehicle && <LoadingIcon/>}
                                        Save vehicle
                                    </Button>
                                </Grid>
                                <Grid item xs={5} container justify="center">
                                    <Button
                                        variant="contained"
                                        color={currentVehicle && currentVehicle.isArchived ? 'default' : 'secondary'}
                                        size="large"
                                        disabled={isSavingVehicle}
                                        onClick={() => onToggleVehicleArchived()}
                                    >
                                        {isSavingVehicle && <LoadingIcon/>}
                                        {`${currentVehicle && currentVehicle.isArchived ? 'Unarchive' : 'Archive'} vehicle`}
                                    </Button>
                                </Grid>
                                <Grid item xs={2} container justify="center" onClick={onMakePDF}>
                                    <Button variant="contained" size="large">
                                        PDF
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </>
            )}
        </Grid>
        <Grid item xs={12} container spacing={1} className={classNames({ [cls.hidden]: !isTabVisible('repairs') })}>
            {isTabVisible('repairs') && <Repairs vehicleId={vehicleId}/>}
        </Grid>
        <Grid item xs={12} container spacing={1} className={classNames({ [cls.hidden]: !isTabVisible('photos') })}>
            {isTabVisible('photos') && <Photos
                vehicleId={vehicleId}
                updateVehiclePhotos={updateVehiclePhotos}
            />}
        </Grid>
    </Grid>;
};

export default AddVehicle;

import {AUTH_USER_LOGOUT, AUTH_USER_UPDATED} from '../actions/action-types';
import {pendingActionType, successActionType, failureActionType} from './utils';

const initialState = {
    isAuthed: false,
    isAuthing: false,
    authError: null,
    authedUser: null,
};

const authUser = (state = initialState, action) => {
    switch (action.type) {
        case pendingActionType(AUTH_USER_UPDATED):
            return {
                ...state,
                ...{isAuthing: true}
            };
        case successActionType(AUTH_USER_UPDATED):
            return {
                ...state,
                ...{
                    isAuthing: false,
                    isAuthed: true,
                    authedUser: {...action.payload},
                }
            };
        case failureActionType(AUTH_USER_UPDATED):
            return {
                ...state,
                ...{
                    isAuthing: false,
                    isAuthed: false,
                    authError: {...action.payload},
                    authedUser: null,
                }
            };
        case AUTH_USER_LOGOUT:
            return {
                ...state,
                ...{
                    isAuthing: false,
                    isAuthed: false,
                    authError: null,
                    authedUser: null,
                }
            };
        default:
            return state;
    }
};

export default authUser;

import {combineReducers} from 'redux';
import user from './user';
import users from './users';
import roles from './roles';
import vehicles from './vehicles';
import repairs from './repairs';

const allReducers = combineReducers({
    authUser: user,
    users: users,
    roles: roles,
    vehicles: vehicles,
    repairs: repairs,
});

export default allReducers;

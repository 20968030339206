import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import cyan from '@material-ui/core/colors/cyan';
import orange from '@material-ui/core/colors/orange';

import Login from './pages/login/Login';
import Main from './pages/main/Main';

import './styles/material-icons.less';
import './styles/roboto.less';
import DashboardContainer from './components/dashboardContainer/DashboardContainer';
import {tryReAuth} from './redux/actions/user';
import AddVehicle from './pages/addVehicle/AddVehicle';
import Users from './pages/users/Users';

const theme = createMuiTheme({
  palette: {
    primary: cyan,
    secondary: orange,
  }
});

const App = () => {
  const dispatch = useDispatch();
  const isAuthed = useSelector(store => store.authUser.isAuthed, shallowEqual);

  // run on every render
  useEffect(() => {
    dispatch(tryReAuth());
  }, [dispatch]);

  if (!isAuthed) {
    return (
        <Router>
          <Route path="/" component={Login}/>
        </Router>
    )
  }

  return (
      <ThemeProvider theme={theme}>
        <Router>
          <DashboardContainer>
            <Route path="/" component={Main} exact/>
            <Route path="/users" component={Users}/>
            <Route path="/vehicles/:vehicleId" component={AddVehicle}/>
          </DashboardContainer>
        </Router>
      </ThemeProvider>
  );
}

export default App;

import React, {useCallback, forwardRef} from 'react';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LoadingIcon from '../../loadingIcon/LoadingIcon';

const useStyles = makeStyles((theme) => ({
    confirmButton: {
        color: '#fff',
        borderColor: theme.palette.success.dark,
        backgroundColor: theme.palette.success.dark,
    },
    cancelButton: {
        color: '#fff',
        borderColor: theme.palette.error.dark,
        backgroundColor: theme.palette.error.dark,
    }
}));

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmModal = ({open, onClose, onConfirm, classes, cancelText = 'Cancel', confirmText = 'Confirm', content = '', title = 'Are you sure?', entity = null, isPending = false}) => {
    const internalClasses = useStyles();
    const {cancelButton, confirmButton} = classes || internalClasses;
    const handleClose = useCallback(() => {
        onClose(entity);
    }, [onClose, entity]);

    const handleConfirm = useCallback(() => {
        onConfirm(entity);
    }, [onConfirm, entity]);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            disableBackdropClick={isPending}
        >
            <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className={cancelButton} variant="contained" startIcon={isPending && <LoadingIcon/>} disabled={isPending}>
                    {cancelText}
                </Button>
                <Button onClick={handleConfirm} className={confirmButton} variant="contained" startIcon={isPending && <LoadingIcon/>} disabled={isPending}>
                    {confirmText}
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default ConfirmModal;

// ensure Promise polyfill is connected
import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import * as serviceWorker from './serviceWorker';
import {applyMiddleware, createStore} from 'redux';
import reducers from './redux/reducers/mainReducer';
import thunkMiddleware from 'redux-thunk';
import {createLogger} from 'redux-logger';
import Root from './Root';

const loggerMiddleware = createLogger();

const store = createStore(
    reducers,
    applyMiddleware(
        thunkMiddleware,
        loggerMiddleware,
    )
);

const rootEl = document.getElementById('root');
const render = Component => {
    return ReactDOM.render(
        <React.StrictMode>
            <Component store={store} />
        </React.StrictMode>,
        rootEl,
    )
}

render(Root);

// ReactDOM.render(
//     <React.StrictMode>
//         <Root store={store}/>
//     </React.StrictMode>,
//     rootEl,
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

if (module.hot) {
    module.hot.accept('./Root', () => {
        const NextApp = require('./Root').default
        render(NextApp);
        // ReactDOM.render(
        //     <NextApp />,
        //     rootEl
        // )
    })
}

import React from 'react';
import {IconButton} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    iconButtonError: {
        color: theme.palette.error.main,
        '&:hover': {
            color: theme.palette.error.dark,
        },
        '&:active': {
            color: theme.palette.error.dark,
        },
        '&:focus': {
            color: theme.palette.error.dark,
        },
    },
}));

const IconButtonDanger = (props) => {
    const classes = useStyles();
    return <IconButton className={classes.iconButtonError} {...props}>{props.children}</IconButton>
}

export default IconButtonDanger;

import React, { useCallback } from 'react';
import { del as LSDel } from '../../helpers/localStorage';
import Button from '@material-ui/core/Button';

const LogoutButton = () => {
    const onLogout = useCallback(() => {
        LSDel('user_token');
        window.location.reload();
    }, []);

    return <Button
        size="small"
        color="primary"
        variant="outlined"
        onClick={onLogout}
    >
        Logout
    </Button>
};

export default LogoutButton;

import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { renderDate } from '../../../helpers/date';
import { renderLeadingZeroNumber, getFixedDecimals } from '../../../helpers/general';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import usePrevious from '../../../hooks/usePrevious';
import { deleteRepair, resetSaveRepairError, saveRepair } from '../../../redux/actions/repairs';
import Alert from '../../../components/alert/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import ConfirmModal from '../../../components/modals/confirm/ConfirmModal';
import usePermissions from '../../../hooks/usePermissions';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    textInput: {
        margin: theme.spacing(1),
    },
    bottomButtonsContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    deleteButton: {
        color: theme.palette.error.dark,
    },
    editButton: {
        color: theme.palette.warning.dark,
    },
    viewButton: {
        color: theme.palette.success.dark,
    },
    successButton: {
        color: theme.palette.success.dark,
    },
    th: {
        fontWeight: 'bold',
    },
    repairRow: {
        '&:hover': {
            backgroundColor: theme.palette.background.default
        }
    },
    noRepairsText: {
        opacity: 0.75,
        textAlign: 'center',
    }
}));

const EMPTY_REPAIR = {
    enteredBy: '',
    workDate: null,
    mileage: '',
    mechanic: '',
    repairCostRT: '',
    repairCostWS: '',
    workPaid: false,
    description: '',
};

const Repairs = ({ vehicleId }) => {
    const cls = useStyles();
    const dispatch = useDispatch();
    const canEdit = usePermissions('any', 'update');

    const [currentRepair, setCurrentRepair] = useState(EMPTY_REPAIR);

    const isSavingRepair = useSelector(store => store.repairs.isSavingRepair)
    const savingRepairError = useSelector(store => store.repairs.savingRepairError, shallowEqual);
    const prevIsSavingRepair = usePrevious(isSavingRepair);
    const [isToastOpen, setToastIsOpen] = useState(false);
    const [isSuccessToastOpen, setSuccessToastIsOpen] = useState(false);
    const storedRepairs = useSelector(store => store.vehicles.editingVehicle && store.vehicles.editingVehicle.repairs, shallowEqual);
    const [repairs, setRepairs] = useState([]);

    const handleCloseToast = useCallback(() => {
        setToastIsOpen(false);
        dispatch(resetSaveRepairError());
    }, [dispatch]);

    const handleCloseSuccessToast = useCallback(() => {
        setSuccessToastIsOpen(false);
    }, []);

    useEffect(() => {
        if (prevIsSavingRepair === true && isSavingRepair === false) {
            // do we need to show error?
            if (savingRepairError) {
                setToastIsOpen(true);
            }
        }
    }, [isSavingRepair, prevIsSavingRepair, savingRepairError]);

    useEffect(() => {
        setRepairs(storedRepairs);
    }, [storedRepairs]);

    const startEditRepair = useCallback((i) => {
        setCurrentRepair(repairs[i] || EMPTY_REPAIR);
    }, [repairs]);

    const updateRepairField = useCallback((field, value) => {
        const newRepair = { ...currentRepair };
        newRepair[field] = value;
        // if (field === 'repairCostRT') {
        //     newRepair.repairCostWS = '';
        // } else if (field === 'repairCostWS') {
        //     newRepair.repairCostRT = '';
        // }
        setCurrentRepair({ ...newRepair });
    }, [currentRepair]);

    const onClearForm = useCallback(async () => {
        setCurrentRepair(EMPTY_REPAIR);
    }, []);

    const onSaveRepair = useCallback(async () => {
        await dispatch(saveRepair(vehicleId, currentRepair));
        setCurrentRepair(EMPTY_REPAIR);
        setSuccessToastIsOpen(true);
    }, [currentRepair, dispatch, vehicleId]);

    const [isConfirmDelete, setConfirmDelete] = useState(false);
    const [repairToDelete, setRepairToDelete] = useState(null);
    const [isDeletingRepair, setDeletingRepair] = useState(false);

    const openDeleteRepairConfirmModal = useCallback((repairId) => {
        setConfirmDelete(true);
        setRepairToDelete({ _id: repairId });
    }, []);

    const confirmDeleteRepair = useCallback(async (repairId) => {

        setDeletingRepair(true);

        await dispatch(deleteRepair(vehicleId, repairId));

        setDeletingRepair(false);
        setConfirmDelete(true);
        setRepairToDelete(null);
        setCurrentRepair(EMPTY_REPAIR);
    }, [dispatch, vehicleId]);

    const cancelDeleteRepair = useCallback(() => {
        setDeletingRepair(false);
        setConfirmDelete(false);
        setRepairToDelete(null);
    }, []);

    return <>
        <Snackbar open={isToastOpen} autoHideDuration={6000} onClose={handleCloseToast}>
            <Alert onClose={handleCloseToast} severity="error" alertTitle="Failed to save">
                {savingRepairError && savingRepairError.message}
            </Alert>
        </Snackbar>
        <Snackbar open={isSuccessToastOpen} autoHideDuration={6000} onClose={handleCloseSuccessToast}>
            <Alert onClose={handleCloseSuccessToast} severity="success" alertTitle="Repair saved">
                {/*Repair successfully saved*/}
            </Alert>
        </Snackbar>
        {repairToDelete && (
            <ConfirmModal
                open={isConfirmDelete}
                onConfirm={confirmDeleteRepair}
                onClose={cancelDeleteRepair}
                entity={repairToDelete}
                content={`You are deleting repair. This is not reversible! Are you sure to delete?`}
                isPending={isDeletingRepair}
            />
        )}
        <Grid item xs={12}>
            <Paper className={cls.paper}>
                <Typography variant="h6" component="h6">
                    Repair details
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            disabled={!canEdit}
                            className={cls.textInput}
                            label="Entered by"
                            type="text"
                            variant="outlined"
                            size="small"
                            value={currentRepair.enteredBy || ''}
                            onChange={({ target }) => updateRepairField('enteredBy', target.value)}
                        />
                        <TextField
                            fullWidth
                            disabled={!canEdit}
                            className={cls.textInput}
                            label="Work date"
                            type="date"
                            variant="outlined"
                            size="small"
                            InputProps={{ inputProps: { max: renderDate(Date.now(), 'YYYY-MM-DD') } }}
                            value={renderDate(currentRepair.workDate || Date.now(), 'YYYY-MM-DD')}
                            onChange={({ target }) => updateRepairField('workDate', target.value)}
                        />
                        <TextField
                            fullWidth
                            disabled={!canEdit}
                            className={cls.textInput}
                            label="Mileage"
                            type="text"
                            variant="outlined"
                            size="small"
                            value={currentRepair.mileage}
                            onChange={({ target }) => updateRepairField('mileage', target.value)}
                        />
                        <TextField
                            fullWidth
                            disabled={!canEdit}
                            className={cls.textInput}
                            label="Mechanic"
                            type="text"
                            variant="outlined"
                            size="small"
                            value={currentRepair.mechanic || ''}
                            onChange={({ target }) => updateRepairField('mechanic', target.value)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth className={cls.textInput} variant="outlined" size="small"
                                     disabled={!canEdit}>
                            <InputLabel htmlFor="outlined-adornment-amount">Repair cost RT</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-amount"
                                type="text"
                                value={getFixedDecimals(currentRepair.repairCostRT)}
                                onChange={({ target }) => updateRepairField('repairCostRT', target.value)}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                labelWidth={110}
                            />
                        </FormControl>
                        <FormControl fullWidth className={cls.textInput} variant="outlined" size="small"
                                     disabled={!canEdit}>
                            <InputLabel htmlFor="outlined-adornment-amount">Repair cost WS</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-amount"
                                type="text"
                                value={getFixedDecimals(currentRepair.repairCostWS)}
                                onChange={({ target }) => updateRepairField('repairCostWS', target.value)}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                labelWidth={115}
                            />
                        </FormControl>
                        <FormControl variant="outlined" fullWidth className={cls.formControl} size="small"
                                     disabled={!canEdit}>
                            <InputLabel id="demo-simple-select-outlined-label">Work paid</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                value={currentRepair.workPaid || false}
                                onChange={({ target }) => updateRepairField('workPaid', target.value)}
                                label="Work paid"
                            >
                                <MenuItem value={true}>Yes</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <FormControl fullWidth className={cls.textInput} variant="outlined" size="small"
                                     disabled={!canEdit}>
                            <InputLabel htmlFor="outlined-adornment-amount">Work description</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-amount"
                                type="text"
                                multiline
                                rows={10}
                                value={currentRepair.description || ''}
                                onChange={({ target }) => updateRepairField('description', target.value)}
                                labelWidth={120}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Paper>
            {canEdit && (
                <Grid container spacing={2} className={cls.bottomButtonsContainer}>
                    {currentRepair && currentRepair._id ? (
                        <Grid item xs={6} container spacing={1}>
                            <Grid item>
                                <Button variant="contained" color="primary" onClick={onSaveRepair}>
                                    Save repairs
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="outlined" color="primary" onClick={onClearForm}>
                                    Add new repair
                                </Button>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid item xs={6} container>
                            <Button variant="contained" color="secondary" onClick={onSaveRepair}>
                                Add repairs
                            </Button>
                        </Grid>
                    )}
                </Grid>
            )}
        </Grid>
        <Grid item xs={12} container>
            <TableContainer component={Paper} className={cls.table}>
                <Table aria-label="simple table">
                    <TableHead style={{ fontWeight: 700 }}>
                        <TableRow>
                            <TableCell className={cls.th}>Repairs</TableCell>
                            <TableCell className={cls.th} align="center">Date</TableCell>
                            <TableCell className={cls.th} align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(repairs || []).map(({ workDate, _id }, i) => (
                            <TableRow
                                key={`${i}-${renderDate(workDate)}`}
                                className={cls.repairRow}
                            >
                                <TableCell component="th" scope="row">
                                    {`REPAIR-${renderLeadingZeroNumber(i + 1)}`}
                                </TableCell>
                                <TableCell align="center">{renderDate(workDate)}</TableCell>
                                <TableCell align="right">
                                    {canEdit ? (
                                        <>
                                            <Button
                                                className={cls.editButton}
                                                onClick={() => startEditRepair(i)}
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                className={cls.deleteButton}
                                                onClick={() => confirmDeleteRepair(_id)}
                                            >
                                                Delete
                                            </Button>
                                        </>
                                    ) : (
                                        <Button
                                            className={cls.viewButton}
                                            onClick={() => startEditRepair(i)}
                                        >
                                            View Details
                                        </Button>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                        {(!repairs || !repairs.length) && (
                            <TableRow className={cls.repairRow}>
                                <TableCell component="th" scope="row" colSpan={3}>
                                    <Typography variant="body1" component="p" className={cls.noRepairsText}>
                                        There are no repairs for this vehicle yet
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    </>;
}

export default Repairs;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Lightbox from '../../../components/lightbox/Lightbox';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { shallowEqual, useSelector } from 'react-redux';
import SinglePhoto from './singlePhoto/SinglePhoto';
import { getFullURL } from '../../../helpers/apiRequests';
import Box from '@material-ui/core/Box';
import ConfirmModal from '../../../components/modals/confirm/ConfirmModal';
import usePermissions from '../../../hooks/usePermissions';
import usePrevious from '../../../hooks/usePrevious';

const useStyles = makeStyles((theme) => ({
    hidden: {
        display: 'none',
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    textInput: {
        margin: theme.spacing(1),
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    bottomButtonsContainer: {
        marginTop: theme.spacing(2),
    },
    deleteButton: {
        color: theme.palette.error.dark,
    },
    editButton: {
        color: theme.palette.warning.dark,
    },
    successButton: {
        color: theme.palette.success.dark,
    },
    primaryPhoto: {
        // width: '100%',
        height: '100%',
        // maxWidth: 300,
        // maxHeight: 200,
        borderRadius: theme.spacing(.5),
        overflow: 'hidden',
        cursor: 'pointer',
        '&:hover .deleteButton': {
            display: 'block',
        }
    },
    primaryPhotoInner: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    secondaryPhoto: {
        width: '100%',
        height: '100%',
        maxWidth: 300,
        maxHeight: 200,
    },
    secondaryPhotoBtn: {
        height: '100%',
    },
    removeButtonContainer: {
        position: 'relative',
    },
    removeButton: {
        position: 'absolute',
        top: theme.spacing(1) * -1,
        right: theme.spacing(1) * -1,
        color: theme.palette.error.main,
        background: 'rgba(200,200,200,.5)',
    },
    singlePhotoWrapper: {
        position: 'relative',
        overflow: 'hidden',
    },
    noPhotosText: {
        opacity: 0.75,
        paddingLeft: theme.spacing(.5),
    }
}));

const Photos = ({ vehicleId, updateVehiclePhotos }) => {
    const cls = useStyles();
    const canEdit = usePermissions('any', 'update');

    const storedPhotos = useSelector(store => store.vehicles.editingVehicle && (store.vehicles.editingVehicle.photos || []), shallowEqual);
    const [needToGetFromStoredPhotos, setNeedToGetFromStoredPhotos] = useState(false);
    const [photos, setPhotos] = useState(storedPhotos || []);
    const [primaryPhoto, setPrimaryPhoto] = useState(null);
    const prevStoredPhotos = usePrevious(storedPhotos);

    useEffect(() => {
        const localPhotos = storedPhotos ? [...storedPhotos] : [];

        const firstPhoto = localPhotos.shift();
        const needToUpdateData =
            (firstPhoto && firstPhoto._id !== (primaryPhoto && primaryPhoto._id)) // primary photo changed
            || ((photos || []).length !== (storedPhotos || []).length - 1);   // amount of photos changed

        if (firstPhoto && needToUpdateData) {
            firstPhoto.isPrimary = true;
            setPrimaryPhoto(firstPhoto);
            setPhotos([...localPhotos]);
        } else if (storedPhotos && !storedPhotos.length && prevStoredPhotos && prevStoredPhotos.length > 0) {
            setPrimaryPhoto(null);
            setPhotos([]);
        }
        setNeedToGetFromStoredPhotos(false);
        // }
    }, [needToGetFromStoredPhotos, photos, prevStoredPhotos, primaryPhoto, storedPhotos]);

    const [previewPhoto, setPreviewPhoto] = useState();
    const openPreview = useCallback((photo) => {
        setPreviewPhoto(photo);
    }, []);

    const onClosePreview = useCallback(() => {
        setPreviewPhoto(null);
    }, []);

    const removePhoto = useCallback((photo) => {
        const newPhotos = [...storedPhotos].filter(sPhoto => sPhoto._id !== photo._id);
        updateVehiclePhotos(newPhotos, true);
    }, [storedPhotos, updateVehiclePhotos]);

    const fileInput = useRef(null);
    const [files, setFiles] = useState([]);

    const openFileSelect = useCallback(() => {
        fileInput.current.click();
    }, []);

    const uploadFile = useCallback((e) => {
        if (e.target && e.target.files.length) {
            const filesArr = Array.from(e.target.files);
            const newFiles = [...files];
            filesArr.forEach((selectedFile) => {
                const fileSelected = files.some(f => f.isNew && f.file.name === selectedFile.name);
                if (!fileSelected) {
                    newFiles.push({
                        isNew: true,
                        file: selectedFile,
                        progress: -1,
                        isUploading: false,
                        needsUpload: true,
                    });
                }
            });
            setFiles(newFiles);
            // updateVehiclePhotos([...newFiles]);
        }
    }, [files]);

    const getFileName = (file) => {
        if(file.file) {
            return file.file.name;
        }
        return file.name;
    };

    const updateFileObj = useCallback((updatedFile) => {
        const newFiles = files.map(f => getFileName(f) === getFileName(updatedFile) ? updatedFile : f);
        setFiles([...newFiles]);
        if (updatedFile.url) {
            const uploadedFile = {...updatedFile};
            delete uploadedFile.file;
            updateVehiclePhotos([...storedPhotos, uploadedFile]);
            setFiles([]);
        }
    }, [files, storedPhotos, updateVehiclePhotos]);

    const [isConfirmDelete, setConfirmDelete] = useState(false);
    const [photoToDelete, setPhotoToDelete] = useState(null);

    const openDeletePhotoConfirmModal = useCallback((event, photo) => {
        event.stopPropagation();
        event.preventDefault();
        setConfirmDelete(true);
        setPhotoToDelete(photo);
    }, []);

    const confirmDeletePhoto = useCallback(async (event, photo) => {
        event.stopPropagation();
        event.preventDefault();
        removePhoto(photoToDelete || photo);
        setConfirmDelete(true);
        setPhotoToDelete(null);
    }, [photoToDelete, removePhoto]);

    const cancelDeletePhoto = useCallback((role) => {
        setConfirmDelete(false);
        setPhotoToDelete(null);
    }, []);

    const closeButtonEl = useCallback((photo) => {
        return <IconButton
            aria-label="delete"
            className={cls.removeButton}
            // onClick={(event) => openDeletePhotoConfirmModal(event, photo)}
            onClick={(event) => confirmDeletePhoto(event, photo)}
        >
            <HighlightOffIcon/>
        </IconButton>;
    }, [cls, confirmDeletePhoto]);

    return <Grid item xs={12}>
        {photoToDelete && (
            <ConfirmModal
                open={isConfirmDelete}
                onConfirm={confirmDeletePhoto}
                onClose={cancelDeletePhoto}
                entity={photoToDelete}
                content={`You are deleting photo. This is not reversible! Are you sure to delete?`}
            />
        )}
        {previewPhoto && (
            <Lightbox isOpen={true} onClose={onClosePreview}>
                <img src={getFullURL(previewPhoto.url)} alt={previewPhoto.name}/>
            </Lightbox>
        )}
        <Paper className={cls.paper}>
            <Typography variant="h6" component="h6">
                CURRENT PHOTOS
            </Typography>
            <Grid container spacing={1}>
                {!primaryPhoto && (!photos || !photos.length) && (
                    <Typography variant="body1" component="div" className={cls.noPhotosText}>
                        There are no photos yet for this vehicle...
                    </Typography>
                )}
                {primaryPhoto && (
                    <Grid item xs={4} onClick={() => openPreview(primaryPhoto)}>
                        <div className={cls.primaryPhoto + ' ' + cls.removeButtonContainer}>
                            <img
                                className={cls.primaryPhotoInner}
                                src={primaryPhoto && primaryPhoto.url && getFullURL(primaryPhoto.url)}
                                alt={primaryPhoto && primaryPhoto.name}
                            />
                            {canEdit && closeButtonEl(primaryPhoto)}
                        </div>
                    </Grid>
                )}
                <Grid container item xs={8} justify="flex-start" alignContent="center" spacing={1}>
                    {photos.map((photo, i) => <Grid item xs={3} key={`vehicle-photo-${i}-total-${photos.length}`}  onClick={() => openPreview(photo)}>
                                <Box className={cls.singlePhotoWrapper}>
                                    <SinglePhoto
                                        photo={photo}
                                        updateFileObj={updateFileObj}
                                        vehicleId={vehicleId}
                                    />
                                    {canEdit && closeButtonEl(photo)}
                                </Box>
                    </Grid>)}
                    {files.map((file, i) => <Grid item xs={3} key={`vehicle-photo-${i + photos.length}-total-${photos.length}`}>
                                <Box className={cls.singlePhotoWrapper}>
                                    <SinglePhoto
                                        photo={file}
                                        updateFileObj={updateFileObj}
                                        vehicleId={vehicleId}
                                    />
                                    {canEdit && closeButtonEl(file)}
                                </Box>
                    </Grid>)}
                </Grid>
            </Grid>
        </Paper>
        {canEdit && (
            <Grid container spacing={2} className={cls.bottomButtonsContainer}>
                <Grid item xs={12} container>
                    <Button variant="contained" color="primary" onClick={openFileSelect}>
                        Upload photo (s)
                    </Button>
                </Grid>
            </Grid>
        )}
        <input type="file" ref={fileInput} style={{display: "none"}} onChange={uploadFile}/>
    </Grid>;
};

export default Photos;

import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Copyright from '../copyright/Copyright';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import usePermissions from '../../hooks/usePermissions';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
        color: "white",
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    content: {
        flexGrow: 1,
        // height: '100vh',
        overflow: 'auto',
    },
    container: {
        // paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    avatar: {
        color: theme.palette.getContrastText(theme.palette.secondary.main),
        backgroundColor: theme.palette.secondary.main,
    },
    rightHeader: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "1rem",
    },
    leftHeader: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        padding: "1rem",
        h2: {
            margin: 0,
        }
    },
}));

const DashboardContainer = ({ children }) => {
    const classes = useStyles();
    const location = useLocation();

    const canManageUsers = usePermissions('user', 'update');
    const canEdit = usePermissions('any', 'update');

    const [isOnUsersPage, setIsOnUsersPage] = useState(false);
    const [isOnVehicleForm, setIsOnVehicleForm] = useState(false);

    useEffect(() => {
        setIsOnVehicleForm(location.pathname.indexOf('/vehicles/') === 0);
        setIsOnUsersPage(location.pathname.indexOf('/users') === 0);
    }, [location.pathname]);

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={6} className={classes.leftHeader}>
                    <Typography variant="h4" component="h4">
                        TLC Inventory
                    </Typography>
                </Grid>
                <Grid item xs={6} container spacing={1} className={classes.rightHeader}>
                    {canManageUsers && (
                        !isOnUsersPage ? (
                            <Grid item>
                                <Button variant="contained" color="secondary" size="large" component={NavLink}
                                        to="/users">
                                    Users
                                </Button>
                            </Grid>
                        ) : (
                            <Grid item>
                                <Button variant="contained" color="secondary" size="large" component={NavLink} to="/">
                                    Back to inventory
                                </Button>
                            </Grid>
                        )
                    )}
                    {isOnVehicleForm ? (
                        <Button variant="contained" color="primary" size="large" component={NavLink} to="/">
                            Back to inventory
                        </Button>
                    ) : (
                        canEdit && (
                            <Button variant="contained" color="primary" size="large" component={NavLink}
                                    to="/vehicles/new">
                                Add vehicle
                            </Button>
                        )
                    )}
                </Grid>
            </Grid>
            <Grid container>
                <main className={classes.content}>
                    <Container maxWidth="lg" className={classes.container}>
                        {children}
                        <Box pt={4}>
                            <Copyright/>
                        </Box>
                    </Container>
                </main>
            </Grid>
        </div>
    );
};

export default DashboardContainer;

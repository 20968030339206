import axios from 'axios';
import authToken from './authToken';

const { REACT_APP_API_DOMAIN: API_DOMAIN } = process.env;

const CancelToken = axios.CancelToken;
const cancelTokens = {};

const fillAuthTokenHeaders = (headers) => {
    const token = authToken();
    if (token) {
        headers.auth_token = token;
        headers['X-Auth-Token'] = token;
    }
    return headers;
}

const getHeaders = (headers = {}) => {
    return fillAuthTokenHeaders({ ...headers });
}

const getJSONHeaders = (headers = {}) => {
    return { ...getHeaders(headers), 'Content-Type': 'application/json' };
}

export const post = async (url, data) => {
    return axios.post(`${API_DOMAIN}/api${url}`, data, {
        headers: getJSONHeaders(),
    });
};
export const get = async (url, data) => {
    return axios.get(`${API_DOMAIN}/api${url}`, {
        params: data,
        headers: getHeaders(),
    });
};
export const del = async (url, data) => {
    return axios.delete(`${API_DOMAIN}/api${url}`, data, {
        headers: getHeaders(),
    });
};

export const postWithProgress = async (url, formData, updateUploadProgressCallback) => {
    const headers = fillAuthTokenHeaders({ 'Content-Type': 'multipart/form-data' });
    return axios({
        method: 'post',
        headers,
        data: formData,
        url: `${API_DOMAIN}/api${url}`,
        onUploadProgress: (ev) => {
            const progress = ev.loaded / ev.total * 100;
            if (updateUploadProgressCallback) {
                updateUploadProgressCallback(progress);
            }
        },
    });
};

// GET Request that may have only one existing instance
// all previous instances will be cancelled
export const singleInstanceGet = async (url, data) => {
    const urlKey = JSON.stringify(url);
    if (cancelTokens[urlKey]) {
        cancelTokens[urlKey].cancel();
    }
    return axios.get(`${API_DOMAIN}/api/${url}`, {
        params: data,
        headers: getHeaders(),
        cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            cancelTokens[urlKey] = { cancel: c };
        }),
    });
};

export const getFullURL = (url) => API_DOMAIN + url;

import {
    EDITING_REPAIR_SAVE,
    EDITING_VEHICLE_CLEAR, EDITING_VEHICLE_FETCH,
    EDITING_VEHICLE_UPDATED, REPAIR_DELETE,
    VEHICLE_SAVE,
    VEHICLE_SAVE_ERROR_RESET,
    VEHICLES_UPDATE
} from '../actions/action-types';
import {pendingActionType, successActionType, failureActionType} from './utils';

const initialState = {
    isFetching: false,
    vehicles: null,
    isSavingVehicle: null,
    savingVehicleError: null,
    editingVehicle: null,
    isFetchingEditingVehicle: false,
};

const vehicles = (state = initialState, action) => {
    let vehicleId, repairs;
    if(
        action.type === successActionType(EDITING_REPAIR_SAVE)
        || action.type === successActionType(REPAIR_DELETE)
    ) {
        vehicleId = action.payload.vehicleId;
        repairs = action.payload.repairs;
        if(state.editingVehicle && state.editingVehicle._id === vehicleId) {
            state.editingVehicle.repairs = [...repairs];
        }
    }

    switch (action.type) {
        case successActionType(EDITING_REPAIR_SAVE):
            return {
                ...state,
                ...{
                    vehicles: state.vehicles.map(vehicle => {
                        if(vehicleId && repairs && vehicle._id === vehicleId) {
                            vehicle.repairs = repairs;
                        }

                        return vehicle;
                    }),
                    editingVehicle: action.payload.vehicle,
                }
            };
        case pendingActionType(VEHICLES_UPDATE):
            return {
                ...state,
                ...{isFetching: true}
            };
        case successActionType(VEHICLES_UPDATE):
            return {
                ...state,
                ...{
                    isFetching: false,
                    vehicles: [...action.payload],
                }
            };
        case failureActionType(VEHICLES_UPDATE):
            return {
                ...state,
                ...{
                    isFetching: false,
                    vehicles: null,
                }
            };
        case pendingActionType(VEHICLE_SAVE):
            return {
                ...state,
                ...{
                    isSavingVehicle: true,
                    savingVehicleError: null,
                }
            };
        case successActionType(VEHICLE_SAVE):
            return {
                ...state,
                ...{
                    isSavingVehicle: false,
                    savingVehicleError: null,
                    editingVehicle: {...action.payload},
                }
            };
        case failureActionType(VEHICLE_SAVE):
            return {
                ...state,
                ...{
                    isSavingVehicle: false,
                    savingVehicleError: action.payload,
                }
            };
        case VEHICLE_SAVE_ERROR_RESET:
            return {
                ...state,
                ...{
                    isSavingVehicle: false,
                    savingVehicleError: null,
                }
            };

        case pendingActionType(EDITING_VEHICLE_FETCH):
            return {
                ...state,
                ...{
                    isFetchingEditingVehicle: true,
                }
            };
        case successActionType(EDITING_VEHICLE_FETCH):
            return {
                ...state,
                ...{
                    isFetchingEditingVehicle: false,
                    editingVehicle: {...action.payload},
                }
            };
        case failureActionType(EDITING_VEHICLE_FETCH):
            return {
                ...state,
                ...{
                    isSavingVehicle: false,
                }
            };
        case EDITING_VEHICLE_UPDATED:
            return {
                ...state,
                ...{
                    editingVehicle: {...state.editingVehicle, ...action.payload},
                    vehicleChanged: true,
                }
            };
        case EDITING_VEHICLE_CLEAR:
            return {
                ...state,
                ...{
                    editingVehicle: null,
                    vehicleChanged: false,
                }
            };
        case successActionType(REPAIR_DELETE):
            const editingVehicle = state.editingVehicle;
            const newVehicle = action.payload.vehicle;
            if(newVehicle && newVehicle.cost) {
                editingVehicle.cost.repairCostRT = newVehicle.cost.repairCostRT
                editingVehicle.cost.repairCostWS = newVehicle.cost.repairCostWS
                editingVehicle.cost.totalCost = newVehicle.cost.totalCost
            }
            return {
                ...state,
                ...{
                    isDeletingRepair: false,
                    editingVehicle: {...editingVehicle},
                }
            };
        default:
            return state;
    }
};

export default vehicles;

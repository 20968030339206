import {del, get, post} from '../../../helpers/apiRequests';
import {failureActionType, pendingActionType, successActionType} from '../../reducers/utils';
import {USER_DELETE, USER_SAVE, USER_SAVE_ERROR_RESET, USERS_UPDATE} from '../action-types';

export const fetchingUsers = () => ({
    type: pendingActionType(USERS_UPDATE),
    payload: null,
});

export const updateUsers = (data) => ({
    type: successActionType(USERS_UPDATE),
    payload: data,
});

export const failureFetchUsers = (data) => ({
    type: failureActionType(USERS_UPDATE),
    payload: null,
});

export const fetchUsers = () => async dispatch => {
    dispatch(fetchingUsers());
    try {
        const json = await get('/user');
        const {data} = json || {};
        dispatch(updateUsers(data && data.users));
    } catch (err) {
        dispatch(failureFetchUsers(err));
    }
}

export const savingUser = () => ({
    type: pendingActionType(USER_SAVE),
    payload: null,
});

export const savedUser = (data) => ({
    type: successActionType(USER_SAVE),
    payload: data,
});

export const failureSavingUser = (data) => ({
    type: failureActionType(USER_SAVE),
    payload: data && data.data && data.data.errors,
});

export const saveUser = (data) => async dispatch => {
    const dataToSave = {...data};
    const isNew = !dataToSave._id;
    const url = '/user' + (isNew ? '' : `/${dataToSave._id}`);

    dispatch(savingUser());
    try {
        const json = await post(url, {...dataToSave});
        const {data} = json || {};
        dispatch(savedUser(data));
    } catch (err) {
        dispatch(failureSavingUser(err && err.response && err.response.data));
    }
};

export const toggleActive = (data) => async dispatch => {
    const url = `/user/${data._id}/toggleActive`;

    dispatch(savingUser());
    try {
        const json = await post(url, {});
        const {data} = json || {};
        dispatch(savedUser(data));
    } catch (err) {
        dispatch(failureSavingUser(err));
    }
};

export const resetSaveUserError = () => ({
    type: USER_SAVE_ERROR_RESET,
    payload: null,
});

export const deletingUser = () => ({
    type: pendingActionType(USER_DELETE),
    payload: null,
});

export const deletedUser = (data) => ({
    type: successActionType(USER_DELETE),
    payload: data,
});

export const failureDeletingUser = (data) => ({
    type: failureActionType(USER_DELETE),
    payload: data,
});

export const deleteUser = (data) => async dispatch => {
    const userId = data._id;
    const url = `/user/${userId}`;

    dispatch(deletingUser());
    try {
        const json = await del(url, {});
        const {data} = json || {};
        dispatch(deletedUser(data));
    } catch (err) {
        dispatch(failureDeletingUser(err));
    }
};

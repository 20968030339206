export const AUTH_USER_UPDATED = 'AUTH_USER_UPDATED';
export const AUTH_USER_LOGOUT = 'AUTH_USER_LOGOUT';

export const ROLES_UPDATE = 'ROLES_UPDATE';
export const ROLE_SAVE = 'ROLE_SAVE';
export const ROLE_DELETE = 'ROLE_DELETE';
export const ROLE_SAVE_ERROR_RESET = 'ROLE_SAVE_ERROR_RESET';

export const USERS_UPDATE = 'USERS_UPDATE';
export const USER_SAVE = 'USER_SAVE';
export const USER_DELETE = 'USER_DELETE';
export const USER_SAVE_ERROR_RESET = 'USER_SAVE_ERROR_RESET';

export const VEHICLES_UPDATE = 'VEHICLES_UPDATE';
export const VEHICLE_SAVE = 'VEHICLE_SAVE';
export const VEHICLE_SAVE_ERROR_RESET = 'VEHICLE_SAVE_ERROR_RESET';

export const EDITING_VEHICLE_SAVE = 'EDITING_VEHICLE_SAVE';
export const EDITING_VEHICLE_FETCH = 'EDITING_VEHICLE_FETCH';
export const EDITING_VEHICLE_UPDATED = 'EDITING_VEHICLE_UPDATED';
export const EDITING_VEHICLE_CLEAR = 'EDITING_VEHICLE_CLEAR';

export const REPAIR_DELETE = 'REPAIR_DELETE';
export const EDITING_REPAIR_SAVE = 'EDITING_REPAIR_SAVE';
export const EDITING_REPAIR_SAVE_ERROR_RESET = 'EDITING_REPAIR_SAVE_ERROR_RESET';
export const EDITING_REPAIR_UPDATED = 'EDITING_REPAIR_UPDATED';
export const EDITING_REPAIR_CLEAR = 'EDITING_REPAIR_CLEAR';
export const EDITING_REPAIR_FRESH_REPAIRS_RECEIVED = 'EDITING_REPAIR_FRESH_REPAIRS_RECEIVED';
export const EDITING_REPAIR_FRESH_REPAIRS_CLEAR = 'EDITING_REPAIR_FRESH_REPAIRS_CLEAR';



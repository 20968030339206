import { del, post } from '../../../helpers/apiRequests';
import { failureActionType, pendingActionType, successActionType } from '../../reducers/utils';
import {
    EDITING_REPAIR_SAVE, EDITING_REPAIR_SAVE_ERROR_RESET,
    EDITING_VEHICLE_CLEAR,
    EDITING_VEHICLE_UPDATED, REPAIR_DELETE,
    VEHICLE_SAVE,
    VEHICLE_SAVE_ERROR_RESET,
} from '../action-types';

export const updateEditingVehicle = (data) => ({
    type: EDITING_VEHICLE_UPDATED,
    payload: data,
});

export const clearEditingVehicle = () => ({
    type: EDITING_VEHICLE_CLEAR,
    payload: null,
});

export const savingVehicle = () => ({
    type: pendingActionType(VEHICLE_SAVE),
    payload: null,
});

export const savedVehicle = (data) => ({
    type: successActionType(VEHICLE_SAVE),
    payload: data,
});

export const failureSavingVehicle = (data) => ({
    type: failureActionType(VEHICLE_SAVE),
    payload: data,
});

export const saveVehicle = (data) => async dispatch => {
    const dataToSave = { ...data };
    const isNew = !dataToSave._id;
    const url = '/vehicles' + (isNew ? '' : `/${dataToSave._id}`);

    dispatch(savingVehicle());
    try {
        const json = await post(url, { ...dataToSave });
        const { data } = json || {};
        dispatch(savedVehicle(data));
    } catch (err) {
        dispatch(failureSavingVehicle(err));
    }
};

export const resetSaveVehicleError = () => ({
    type: VEHICLE_SAVE_ERROR_RESET,
    payload: null,
});

export const savingRepair = () => ({
    type: pendingActionType(EDITING_REPAIR_SAVE),
    payload: null,
});

export const savedRepair = (data) => ({
    type: successActionType(EDITING_REPAIR_SAVE),
    payload: {
        vehicleId: data.vehicleId,
        repairs: data.vehicle.repairs,
        vehicle: data.vehicle,
    },
});

export const failureSavingRepair = (data) => ({
    type: failureActionType(EDITING_REPAIR_SAVE),
    payload: data,
});

export const saveRepair = (vehicleId, data) => async dispatch => {
    const dataToSave = { ...data };
    const url = `/vehicles/${vehicleId}/repairs`;

    dispatch(savingRepair());
    try {
        const json = await post(url, { ...dataToSave });
        const { data } = json || {};
        dispatch(savedRepair(data));
    } catch (err) {
        dispatch(failureSavingRepair(err));
    }
};

export const resetSaveRepairError = () => ({
    type: EDITING_REPAIR_SAVE_ERROR_RESET,
    payload: null,
});

export const deletingRepair = () => ({
    type: pendingActionType(REPAIR_DELETE),
    payload: null,
});

export const deletedRepair = (data) => ({
    type: successActionType(REPAIR_DELETE),
    payload: {
        vehicleId: data.vehicleId,
        repairs: data.vehicle.repairs,
        vehicle: data.vehicle,
    },
});

export const failureDeletingRepair = (data) => ({
    type: failureActionType(REPAIR_DELETE),
    payload: data,
});

export const deleteRepair = (vehicleId, repairId) => async dispatch => {
    const url = `/vehicles/${vehicleId}/repairs/${repairId}`;

    dispatch(deletingRepair());
    try {
        const json = await del(url, {});
        const {data} = json || {};
        dispatch(deletedRepair(data));
    } catch (err) {
        dispatch(failureDeletingRepair(err));
    }
};
